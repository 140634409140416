import { Button, InputAdornment, TextField } from '@mui/material';
import { useRef, useState } from 'react';

type Props = {
  name?: string;
  accept?: string;
  label: string;
  defaultValue?: string;
  onSelect?: (file: File) => void;
  size?: 'small' | 'medium';
};

export default function FileInput(props: Props) {
  const { name, label, accept, defaultValue, onSelect } = props;
  const [value, setValue] = useState(defaultValue || '');
  const ref = useRef<HTMLInputElement>(null);

  const onClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    ref.current?.click();
  };

  const onChange = (e: any) => {
    const file = e.target.files[0];
    if (file) setValue(file.name);
    if (onSelect) onSelect(file);
  };

  return (
    <>
      <input ref={ref} hidden type="file" accept={accept} onChange={onChange} />
      <TextField
        size={props.size}
        label={label}
        name={name}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button onClick={onClick}>Select</Button>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}
