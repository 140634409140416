/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfirmation, useRequester } from '../../hooks';
import { useEffect, useState } from 'react';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  FontDownload as FontDownloadIcon,
  MoreHoriz as MoreHorizIcon,
  Refresh,
} from '@mui/icons-material';
import { fromNow } from '../../utils';
import { ArticleData, ArticleResource } from '../../resources';
import { Menu } from '../../design';
import ArticleWordListModal from './ArticleWordListModal';

export default function ArticlePage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [showWordList, setShowWordList] = useState(false);

  const deleteConfirmation = useConfirmation<ArticleData>({
    getTitle: (article) => 'Delete this article?',
    getDescription: (article) =>
      `You are about to delete '${article.title}'. This action cannot be undone.`,
    onConfirm: async (article) => {
      await ArticleResource.deleteOne(article.id);
      navigate('/articles');
    },
  });

  const fetchArticle = useRequester<ArticleResource>({
    onSubmit: (id) => ArticleResource.fetchOne(id),
    onError: console.log,
  });

  const generateAudio = useRequester<any>({
    onSubmit: () =>
      id ? ArticleResource.generateAudio(id) : Promise.resolve(),
    onError: console.log,
  });

  useEffect(() => {
    fetchArticle.submit(id);
  }, [id]);

  if (!id) return <Alert severity="error">No article ID provided</Alert>;
  const article = ArticleResource.get(id).data as ArticleData;

  if (!article) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        {fetchArticle.error ? (
          <Alert severity="error">{fetchArticle.error}</Alert>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  return (
    <Box className="flex flex-col gap-4">
      <Box>
        <Box className="flex flex-row gap-2">
          <Typography variant="h5" fontWeight="bold">
            {article.title}
          </Typography>
          <Box>
            <Menu
              anchor={
                <IconButton size="small" color="primary">
                  <MoreHorizIcon fontSize="small" />
                </IconButton>
              }
              items={[
                {
                  name: 'edit',
                  label: 'Edit Article',
                  onSelect: () => navigate(`/articles/${id}/edit`),
                  icon: <EditIcon />,
                },
                {
                  name: 'dict',
                  label: 'Generate Dictionary',
                  onSelect: () => setShowWordList(true),
                  icon: <FontDownloadIcon />,
                },
                {
                  name: 'refresh-audio',
                  label: 'Refresh audio',
                  onSelect: generateAudio.submit,
                  icon: <Refresh />,
                },
                {
                  name: 'delete',
                  label: 'Delete Article',
                  onSelect: () => deleteConfirmation.triggerFor(article),
                  icon: <DeleteIcon />,
                  color: 'error',
                },
              ]}
            />
          </Box>
        </Box>
        <Typography variant="h6">{article.titleJp}</Typography>
        <Box className="flex gap-1">
          <Typography variant="body2" color="text.secondary">
            From {article.media.title} {fromNow(article.publishedAt)}
            &nbsp; &bull; &nbsp;
            {article.tags.map((tag) => (
              <Chip
                component="span"
                key={tag}
                label={tag}
                variant="outlined"
                color="primary"
                size="small"
              />
            ))}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="grid grid-cols-4 gap-4">
        <Box className="flex flex-col gap-4">
          {article.video ? (
            <video width="100%" controls src={article.video} />
          ) : (
            <Alert severity="warning">No video</Alert>
          )}

          {generateAudio.loading ? (
            <Alert icon={<CircularProgress size={22} />}>
              Generating audio
            </Alert>
          ) : article.audio ? (
            <audio
              className="w-full"
              controls
              src={`${article.audio}?key=${Math.random()}`} // Appending a random key to force refresh on audio regeneration
            />
          ) : (
            <Alert severity="warning">No Audio</Alert>
          )}

          {article.image ? (
            <img src={article.image} alt="" />
          ) : (
            <Alert severity="warning">No image</Alert>
          )}
        </Box>
        <Box className="flex flex-col gap-4 col-span-3">
          <Box className="flex flex-col gap-2">
            <Typography variant="h6" fontWeight="bold">
              Description
            </Typography>
            <Typography textAlign="justify">{article.description}</Typography>
            <Typography textAlign="justify">{article.descriptionJp}</Typography>
          </Box>
          <Box className="flex flex-col gap-2">
            <Typography variant="h6" fontWeight="bold">
              Content
            </Typography>
            <Typography textAlign="justify" whiteSpace="break-spaces">
              {article.content}
            </Typography>
            <Typography textAlign="justify" whiteSpace="break-spaces">
              {article.contentJp}
            </Typography>
          </Box>
        </Box>
      </Box>
      <ArticleWordListModal
        open={showWordList}
        articleId={id}
        onClose={() => setShowWordList(false)}
      />
    </Box>
  );
}
