import './App.css';
import {
  Home as HomeIcon,
  MonitorHeart,
  Newspaper as NewsIcon,
  Person2 as PersonIcon,
  Source as SourceIcon,
  FontDownload as FontDownloadIcon,
  SmartToy as SmartToyIcon,
  Tag as TagIcon,
  LocationOn as LocationIcon,
} from '@mui/icons-material';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useContext } from 'react';
import cookie from 'js-cookie';
import Layout from './layout/Layout';
import {
  ActivitiesPage,
  ArticleEditPage,
  ArticlePage,
  ArticlesPage,
  DictionaryPage,
  HomePage,
  LoginPage,
  MediaEditPage,
  MediaPage,
  TutorEditPage,
  TutorPage,
  TutorsPage,
  UsersPage,
  TagsPage,
  LocationsPage,
  LocationEditPage,
  LocationPage,
} from './pages';
import { AuthContext } from './contexts';
import { BaseResource } from './resources';

interface IRoute {
  title?: string;
  path: string;
  icon?: JSX.Element;
  Component: () => JSX.Element;
  scope?: 'vr' | 'lite';
}

const routes: IRoute[] = [
  {
    title: 'Home',
    icon: <HomeIcon />,
    path: '/',
    Component: HomePage,
  },
  {
    title: 'Activities',
    path: '/activities',
    icon: <MonitorHeart />,
    Component: ActivitiesPage,
    scope: 'lite',
  },
  {
    path: '/articles/new',
    Component: ArticleEditPage,
    scope: 'lite',
  },
  {
    path: '/articles/:id',
    Component: ArticlePage,
    scope: 'lite',
  },
  {
    path: '/articles/:id/edit',
    Component: ArticleEditPage,
    scope: 'lite',
  },
  {
    title: 'Articles',
    path: '/articles',
    icon: <NewsIcon />,
    Component: ArticlesPage,
    scope: 'lite',
  },
  {
    path: '/media/new',
    Component: MediaEditPage,
    scope: 'lite',
  },
  {
    path: '/media/:id/edit',
    Component: MediaEditPage,
    scope: 'lite',
  },
  {
    title: 'Media',
    path: '/media',
    icon: <SourceIcon />,
    Component: MediaPage,
    scope: 'lite',
  },
  {
    title: 'Tutors',
    path: '/tutors',
    icon: <SmartToyIcon />,
    Component: TutorsPage,
  },
  {
    title: 'Dictionary',
    path: '/dictionary',
    icon: <FontDownloadIcon />,
    Component: DictionaryPage,
    scope: 'lite',
  },
  {
    title: 'Users',
    path: '/users',
    icon: <PersonIcon />,
    Component: UsersPage,
  },
  {
    path: '/tutors/new',
    Component: TutorEditPage,
  },
  {
    path: '/tutors/:id/edit',
    Component: TutorEditPage,
  },
  {
    path: '/tutors/:id',
    Component: TutorPage,
  },
  {
    title: 'Tags',
    icon: <TagIcon />,
    path: '/tags',
    Component: TagsPage,
  },
  {
    title: 'Locations',
    icon: <LocationIcon />,
    path: '/locations',
    Component: LocationsPage,
    scope: 'lite',
  },
  {
    path: '/locations/new',
    Component: LocationEditPage,
  },
  {
    path: '/locations/:id/edit',
    Component: LocationEditPage,
  },
  {
    path: '/locations/:id',
    Component: LocationPage,
  },
];

function App() {
  const { user } = useContext(AuthContext);

  if (!cookie.get('token') && !user) {
    return (
      <Routes>
        <Route path="/login" Component={LoginPage} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  const scopedRoutes = routes.filter(
    (route) => !route.scope || route.scope === BaseResource.scope
  );

  return <Layout routes={scopedRoutes} />;
}

export default App;
