import BaseResource from './BaseResource';

export interface DictionaryData {
  id: string;
  word: string;
  explanation: string;
  explanationJp: string;
}

class DictionaryResource extends BaseResource<DictionaryData> {
  static get storeName(): string {
    return 'DictionaryResource';
  }

  static get endpoint(): string {
    return '/dictionary/search';
  }

  static async fetchOne(id: string) {
    await this.http.get(`/dictionary?word=${id}&langCode=en`);
    const raw: any = await this.http.get(`/dictionary?word=${id}&langCode=jp`);
    return this.upsertAndReturn(raw);
  }

  static async updateOne(id: string, data: any) {
    const raw: any = await this.http.patch(`/dictionary?word=${id}`, {}, data);
    return this.upsertAndReturn(raw);
  }

  static async deleteOne(id: string) {
    await this.http.delete(`/dictionary?word=${id}`);
    return this.deleteAndReturn(id);
  }

  static toObject(raw: any): DictionaryResource {
    raw.id = raw.word;
    return new DictionaryResource(raw as DictionaryData);
  }
}

export default DictionaryResource;
