import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
  TaskAlt as TaskAltIcon,
} from '@mui/icons-material';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { MediaResource } from '../../resources';
import { useRequester } from '../../hooks';
import { fromNow } from '../../utils';
import { ExternalArticle } from '../../resources/MediaResource';

type Props = {
  mediaId?: string;
  open: boolean;
  onClose: () => void;
  onSelect: (article: any) => void;
};

type ArticleEntryProps = {
  article: ExternalArticle;
  onSelect: () => void;
  selected: boolean;
};

function ArticleEntry(props: ArticleEntryProps) {
  const { article, onSelect, selected } = props;

  return (
    <>
      <Divider />
      <Box
        className={classNames({
          'bg-gray-200': selected,
          'bg-white': !selected,
        })}
      >
        <Typography
          variant="body1"
          fontWeight={500}
          textAlign="justify"
          className="cursor-pointer"
          onClick={onSelect}
        >
          {article.title}
        </Typography>
        <Typography variant="body1" textAlign="justify">
          {article.titleJp}
        </Typography>
      </Box>
    </>
  );
}

type ArticleBodyProps = {
  article: ExternalArticle;
};

function ArticleBody(props: ArticleBodyProps) {
  const { article } = props;

  if (!article) {
    return <Alert severity="error">Failed to fetch the full content</Alert>;
  }

  return (
    <Box className="flex flex-col gap-4 flex-grow overflow-hidden">
      <Box className="flex flex-col gap-1">
        <Typography variant="h6">{article.title}</Typography>
        <Typography variant="body1">{article.titleJp}</Typography>
        {article.publishedAt && (
          <Typography variant="body2" color="text.secondary">
            Published {fromNow(article.publishedAt)}
          </Typography>
        )}
      </Box>
      <Divider />
      {article.content || article.contentJp ? (
        <Box className="flex flex-col gap-2 overflow-auto">
          <Typography variant="body1" textAlign="justify">
            <Box className="float-left mr-8 mb-2 flex flex-col gap-4 mr-4 mb-4">
              {article.audio && (
                <audio controls src={article.audio} className="w-80" />
              )}
              {article.image && (
                <img src={article.image} alt={article.title} className="w-80" />
              )}
            </Box>

            {article.content}
            <span className="block mb-2"></span>
            {article.contentJp}
          </Typography>
        </Box>
      ) : (
        <Box className="flex flex-col items-center h-full justify-center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default function ExternalArticleModal({
  mediaId,
  onClose,
  onSelect,
}: Props) {
  const media = mediaId ? MediaResource.get(mediaId).data : undefined;

  const [articleId, setArticleId] = useState<string>();
  const [currentMediaid, setCurrentMediaId] = useState<string>();

  const [articleStore, setArticleStore] = useState<{
    [key: string]: ExternalArticle;
  }>({});

  const getArticle = (id: string) => articleStore[id];

  const fetchArticles = useRequester<any[]>({
    onSubmit: (id) => MediaResource.fetchExternalArticles(id),
    onSuccess: (data) => {
      setArticleStore((prevStore) => {
        const newStore = { ...prevStore };
        data.forEach((article) => {
          if (!newStore[article.id]) newStore[article.id] = article;
          else {
            for (const key in article) {
              const val = article[key];
              // @ts-ignore
              if (val) newStore[article.id][key] = val;
            }
          }
        });
        return newStore;
      });
    },
  });

  useEffect(() => {
    if (!mediaId) return;
    if (mediaId !== currentMediaid) {
      setCurrentMediaId(mediaId);
      setArticleStore({});
    }

    fetchArticles.submit(mediaId);
  }, [mediaId]);

  const articles = Object.values(articleStore);

  return (
    <Modal open={!!mediaId} onClose={onClose}>
      <Box className="bg-white absolute top-12 right-12 bottom-12 left-12 p-8 gap-4 flex flex-col">
        <Box className="flex justify-between">
          <Typography variant="h5" fontWeight={500}>
            Articles from {media?.title} API
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        {!articles.length && fetchArticles.loading ? (
          <Box className="flex flex-col items-center h-full justify-center">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="grid grid-cols-3 gap-8 flex flex-grow overflow-hidden">
            <Box
              className={classNames('overflow-auto flex flex-col gap-2', {
                'col-span-3': !articleId,
              })}
            >
              {articles?.map((article) => (
                <ArticleEntry
                  key={article.id}
                  article={article}
                  onSelect={() => {
                    setArticleId(article.id);
                  }}
                  selected={articleId === article.id}
                />
              ))}
            </Box>
            {articleId && (
              <Box className="col-span-2 flex flex-col gap-4 overflow-hidden">
                <ArticleBody article={getArticle(articleId)} />
                <Divider />
                <Box textAlign="right">
                  <Button
                    variant="contained"
                    onClick={() => onSelect(getArticle(articleId))}
                    startIcon={<TaskAltIcon />}
                  >
                    Select
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
}
