import BaseResource from './BaseResource';

interface Voice {
  service: string;
  code: string;
  speed: {
    beginner: number;
    intermediate: number;
    advanced: number;
  };
  accent: string;
}

interface PromptSet {
  beginner: string;
  intermediate: string;
  advanced: string;
}

export interface TutorData {
  id: string;
  name: string;
  identification: string;
  avatar: string;
  bio: string;
  voice: Voice;
  voiceJp: Voice;
  tags: string[];
  intro: string;
  contextPrompt: {
    beginner: string;
    intermediate: string;
    advanced: string;
  };
  summaryPrompt: string;
  hintPrompt: string;
  grammarFeedbackPrompt: string;
  generalFeedbackPrompt: string;
  missionPrompt: string;
  prompts: { [key: string]: string | PromptSet };
  color: string;
  scope: 'vr' | 'lite' | null;
}

export interface CreateTutorFormData {
  name: string;
  identification: string;
  voiceServiceJp: string;
  voiceServiceEn: string;
  voiceCodeJp: string;
  voiceCodeEn: string;
  voiceAccentJp: string;
  voiceAccentEn: string;
  voiceSpeedBeginnerJp: number;
  voiceSpeedBeginnerEn: number;
  voiceSpeedIntermediateJp: number;
  voiceSpeedIntermediateEn: number;
  voiceSpeedAdvancedJp: number;
  voiceSpeedAdvancedEn: number;
  tags: string[];
  bio: string;
  intro: string;
  contextPromptBeginner: string;
  contextPromptIntermediate: string;
  contextPromptAdvanced: string;
  summaryPrompt: string;
  hintPrompt: string;
  grammarFeedbackPrompt: string;
  generalFeedbackPrompt: string;
  missionPrompt: string;
}

class TutorResource extends BaseResource<TutorData> {
  static get storeName(): string {
    return 'TutorResource';
  }

  static get endpoint(): string {
    return `/${this.scope}/tutors`;
  }

  static toObject(raw: any): TutorResource {
    return new TutorResource(raw as TutorData);
  }

  static getPrompt(
    data: TutorData | undefined,
    promptName: string,
    difficulty: 'beginner' | 'intermediate' | 'advanced' = 'intermediate'
  ): string {
    if (!data) return '';

    const prompt = data.prompts?.[promptName];
    if (!prompt) return '';

    if (typeof prompt === 'string') return prompt;

    return prompt[difficulty] || '';
  }
}

export default TutorResource;
