import { TextField, Autocomplete as MuiAutocomplete } from '@mui/material';
import * as _ from 'lodash';
import { useMemo, useState } from 'react';

type Option = {
  inputLabel?: string;
  label: string;
  value: string;
};

type Props = {
  onChange?: (value: string | null) => void;
  getOptions?: (value: string) => Promise<Option[]>;
  label: string;
  value?: string;
  disabled?: boolean;
};

export default function Autocomplete(props: Props) {
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const { onChange, label, value, getOptions, disabled } = props;

  const debouncedGetOptions = useMemo(() => {
    if (!getOptions) return undefined;

    return _.debounce((q: string) => {
      setOptions([]);
      setLoading(true);
      getOptions(q)
        .then(setOptions)
        .catch(console.error)
        .finally(() => setLoading(false));
    }, 500);
  }, [getOptions]);

  return (
    <MuiAutocomplete
      value={value}
      loading={loading}
      disabled={disabled}
      loadingText="Loading..."
      onChange={(e, val) => {
        if (!val) onChange?.(null);
        else if (typeof val === 'string') onChange?.(val);
        else onChange?.(val.value);
      }}
      onInputChange={(e, val) => debouncedGetOptions?.(val)}
      options={options}
      renderOption={(props, option) => (
        <li {...props}>{option.inputLabel || option.label}</li>
      )}
      freeSolo
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
