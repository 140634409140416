/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfirmation, useRequester } from '../../hooks';
import { LocationResource, CategoryData, LocationData } from '../../resources';
import { useEffect, useState } from 'react';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import CategoryFormModal from './CategoryFormModal';

export default function LocationPage() {
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory] = useState<CategoryData>();

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const deleteConfirmation = useConfirmation<LocationData>({
    getTitle: (location: LocationData) => `Delete location ${location.name}?`,
    getDescription: (location: LocationData) =>
      `You are about to delete ${location.name}. This action cannot be undone.`,
    onConfirm: async (location: LocationData) => {
      await LocationResource.deleteOne(location.id);
      navigate('/locations');
    },
  });

  const deleteCategoryConfirmation = useConfirmation<string>({
    getTitle: (category: string) => `Delete category ${category}?`,
    getDescription: (category: string) =>
      `You are about to delete category ${category}. This action cannot be undone.`,
    onConfirm: async (category: string) => {
      await LocationResource.deleteCategory(location.id, category);
    },
  });

  const fetchLocation = useRequester<LocationResource>({
    onSubmit: (id) => LocationResource.fetchOne(id),
    onError: console.log,
  });

  useEffect(() => {
    fetchLocation.submit(id);
  }, [id]);

  if (!id) return <Alert severity="error">No location ID provided</Alert>;
  const location = LocationResource.get(id).data as LocationData;
  if (!location) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        {fetchLocation.error ? (
          <Alert severity="error">{fetchLocation.error}</Alert>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  return (
    <Box className="flex flex-col gap-4">
      <Box className="flex flex-row gap-4">
        <Avatar
          variant="square"
          alt={location.name}
          src={location.flag}
          sx={{ height: 100, width: 130 }}
        />
        <Box>
          <Box className="flex flex-row gap-2">
            <Typography variant="h5" fontWeight="bold">
              {location.name} ({location.nameJp})
            </Typography>

            <IconButton
              size="small"
              color="primary"
              onClick={() => navigate(`/locations/${id}/edit`)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              color="error"
              sx={{ ml: -1 }}
              onClick={() => deleteConfirmation.triggerFor(location)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
          <Typography>
            Latitude: {location.position.latitude}, Longitude:{' '}
            {location.position.longitude}
          </Typography>
          <Box className="flex gap-2 mt-2">
            {location.tags.map((tag) => (
              <Chip key={tag} label={tag} variant="outlined" color="primary" />
            ))}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box className="flex flex-col gap-1">
        <Typography variant="h6" fontWeight="bold">
          Description
        </Typography>
        <Box className="flex flex-row gap-4">
          {location.image && (
            <img src={location.image} alt="" style={{ width: '400px' }} />
          )}
          <Box className="flex flex-grow">
            <Box className="grid grid-cols-2 gap-4">
              <Typography>{location.description}</Typography>
              <Typography>{location.descriptionJp}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box className="flex flex-col gap-1">
        <Box className="flex flex-row gap-4">
          <Typography variant="h6" fontWeight="bold">
            Categories
          </Typography>
          <Button onClick={() => setShowModal(true)}>Add</Button>
        </Box>

        <Box className="flex flex-col gap-4">
          {location.categories?.map((category) => (
            <Box key={category.name} className="flex flex-row border p-4 gap-4">
              {category.image && (
                <img
                  src={category.image}
                  alt=""
                  style={{ width: '180px', height: '130px' }}
                />
              )}

              <Box className="flex flex-col flex-grow gap-2">
                <Box className="flex flex-row gap-1">
                  <Typography fontWeight="bold">
                    {category.name} ({category.nameJp})
                  </Typography>
                  <IconButton
                    size="small"
                    color="primary"
                    sx={{ my: -0.5 }}
                    onClick={() => {
                      setCategory(category);
                      setShowModal(true);
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>

                  <IconButton
                    size="small"
                    color="error"
                    sx={{ my: -0.5 }}
                    onClick={() =>
                      deleteCategoryConfirmation.triggerFor(category.name)
                    }
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>

                <Box className="grid grid-cols-2 gap-4">
                  <Typography>{category.description}</Typography>
                  <Typography>{category.descriptionJp}</Typography>
                </Box>
                <Box className="flex gap-1 flex-wrap">
                  {category.items.map((item) => (
                    <Chip key={item} label={item} size="small" />
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Divider />

      <CategoryFormModal
        category={category}
        location={location}
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setCategory(undefined);
        }}
      />
    </Box>
  );
}
