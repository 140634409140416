/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Switch,
  Typography,
} from '@mui/material';
import { Searchbar, Table } from '../../design';
import { useRequester } from '../../hooks';
import { useEffect, useState } from 'react';
import { UserResource } from '../../resources';

interface Row {
  id: string;
  name: string;
  email: string;
  username: string;
  superuser: boolean;
}

function SuperuserToggle(props: Row) {
  const [state, setState] = useState(props.superuser);

  const toggleApi = useRequester<UserResource>({
    onSubmit: () => UserResource.updateOne(props.id, { superuser: !state }),
    onSuccess: () => setState(!state),
    onError: () => UserResource.showAlert('Failed to update user', 'error'),
  });

  return (
    <Switch
      disabled={toggleApi.loading}
      checked={state}
      onChange={() => toggleApi.submit()}
    />
  );
}

const columns = [
  { id: 'name', label: 'Full name', minWidth: 170 },
  { id: 'email', label: 'Email' },
  { id: 'username', label: 'Username' },
  { id: 'superuser', label: 'Superuser', Component: SuperuserToggle },
];

const toRow = (ob: UserResource): Row => {
  const data = ob.data;

  return {
    id: data.id,
    name: `${data.firstName} (${data.lastName})`,
    email: data.email,
    username: data.username,
    superuser: data.superuser,
  };
};

type Query = {
  qs?: string;
  offset: number;
  limit: number;
};

export default function UsersPage() {
  const [hasReachedBottom, setHasReachedBottom] = useState(false);
  const [query, setQuery] = useState<Query>({ offset: 0, limit: 50 });
  const usersApi = useRequester<UserResource[]>({
    onSubmit: (q) => UserResource.fetchMany(q),
    onSuccess: (data) => setHasReachedBottom(data.length < query.limit),
    onError: console.log,
  });

  useEffect(() => {
    usersApi.submit(query);
  }, [query.qs, query.offset]);

  const rows = UserResource.list().map(toRow);

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-4">
          <Typography variant="h5" fontWeight="bold">
            Users
          </Typography>
          <Searchbar
            onChange={(qs) => setQuery({ qs, offset: 0, limit: 50 })}
          />
          {usersApi.loading && (
            <Box className="flex items-center">
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
      </Box>
      <Box className="h-4" />

      {usersApi.error ? (
        <Alert severity="error">{usersApi.error}</Alert>
      ) : (
        <Table columns={columns} rows={rows} />
      )}
      {!hasReachedBottom && rows.length > 0 && (
        <Button
          disabled={usersApi.loading}
          sx={{ mt: 2 }}
          fullWidth
          onClick={() =>
            setQuery({ ...query, offset: query.offset + query.limit })
          }
        >
          Load More
        </Button>
      )}
    </Box>
  );
}
