/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Box, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useRequester } from '../../hooks';
import { MediaData, MediaResource } from '../../resources';
import MediaForm from './MediaForm';

export default function MediaEditPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const fetchMedia = useRequester<MediaResource>({
    onSubmit: (id) => MediaResource.fetchOne(id),
    onError: console.log,
  });

  const saveMedia = useRequester<MediaResource>({
    onSubmit: (data: any) =>
      id ? MediaResource.updateOne(id, data) : MediaResource.createOne(data),
    onSuccess: (data) => navigate('/media'),
    onError: console.log,
  });

  useEffect(() => {
    id && fetchMedia.submit(id);
  }, [id]);

  let media;
  if (id) media = MediaResource.get(id).data as MediaData;

  if (id && !media) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        {fetchMedia.error ? (
          <Alert severity="error">{fetchMedia.error}</Alert>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  return (
    <Box>
      <MediaForm
        label={id ? 'Edit Media' : 'New Media'}
        onSubmit={saveMedia.submit}
        data={media}
        saving={saveMedia.loading}
        error={saveMedia.error}
      />
    </Box>
  );
}
