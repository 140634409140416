import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Form } from '../../design';
import { useRequester } from '../../hooks';
import { TagData, TagResource } from '../../resources';

type Props = {
  tag?: TagData;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export default function TagFormModal({ tag, open, onClose, onSuccess }: Props) {
  const saveTag = useRequester<TagResource>({
    onSubmit: (data: any) =>
      tag ? TagResource.updateOne(tag.id, data) : TagResource.createOne(data),
    onSuccess,
    onError: console.log,
  });

  const handleSubmit = (data: any) => {
    if (data.type === '') data.type = null;
    saveTag.submit(data);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="bg-white absolute top-1/2 left-1/2 w-[600px] transform -translate-x-1/2 -translate-y-1/2 p-8 gap-4 flex flex-col">
        <Box className="flex justify-between">
          <Box className="flex flex-row">
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              {tag ? `Edit Tag "${tag.name}"` : 'New Tag'}
            </Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4"
          disabled={saveTag.loading}
        >
          {!tag && ( // Only show name field when creating a new tag
            <TextField
              required
              name="name"
              label="Name"
              fullWidth
              size="small"
            />
          )}
          <FormControl size="small" fullWidth>
            <InputLabel id="select-type">Type</InputLabel>
            <Select
              labelId="select-type"
              name="type"
              label="Type"
              defaultValue={tag?.type || ''}
            >
              <MenuItem value="">-</MenuItem>
              <MenuItem value="country">Country</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="label"
            label="label"
            fullWidth
            size="small"
            defaultValue={tag?.label}
          />
          <TextField
            name="labelJp"
            label="Label (JP)"
            fullWidth
            size="small"
            defaultValue={tag?.labelJp}
          />

          <TextField
            name="description"
            label="Description"
            fullWidth
            multiline
            size="small"
            defaultValue={tag?.description}
          />

          <Divider />
          <Box className="flex flex-row gap-4">
            <Button
              startIcon={
                saveTag.loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <SaveIcon sx={{ height: 20, width: 20 }} />
                )
              }
              type="submit"
              variant="contained"
              disabled={saveTag.loading}
            >
              Save
            </Button>
            {saveTag.error && <Alert severity="error">{saveTag.error}</Alert>}
          </Box>
        </Form>
      </Box>
    </Modal>
  );
}
