import { createContext, useEffect, useState } from 'react';
import cookie from 'js-cookie';
import { UserResource } from '../resources';

interface IAuthContext {
  user: UserResource | null;
  setUser: (user: UserResource | null) => void;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => {},
});

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [user, setUser] = useState<UserResource | null>(null);

  useEffect(() => {
    if (cookie.get('token')) {
      UserResource.refresh().then(setUser).catch(console.log);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
}
