import * as React from 'react';
import { Menu as MuiMenu, Box, MenuItem, Typography } from '@mui/material';

type Props = {
  anchor: React.ReactElement;
  items: itemProps[];
};

type SelectCallback = () => void | Promise<void>;

type itemProps = {
  name: string;
  label: string;
  onSelect: SelectCallback;
  icon?: React.ReactElement;
  color?: string;
};

export default function Menu({ anchor, items }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [busy, setBusy] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getSelectHandler = (fn: SelectCallback) => {
    return async () => {
      setBusy(true);
      await fn();
      handleClose();
      setBusy(false);
    };
  };

  return (
    <Box>
      {React.cloneElement(anchor, {
        onClick: handleClick,
      })}
      <MuiMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map((item: itemProps) => (
          <MenuItem
            key={item.name}
            onClick={getSelectHandler(item.onSelect)}
            disabled={busy}
          >
            {item.icon &&
              React.cloneElement(item.icon, {
                className: 'mr-2',
                fontSize: 'inherit',
                color: item.color || 'inherit',
              })}
            <Typography color={item.color || 'inherit'} variant="inherit">
              {item.label}
            </Typography>
          </MenuItem>
        ))}
      </MuiMenu>
    </Box>
  );
}
