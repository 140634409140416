/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Switch,
  Typography,
} from '@mui/material';
import { Table } from '../../design';
import { useConfirmation, useRequester } from '../../hooks';
import { useEffect, useState } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { LocationData, LocationResource } from '../../resources';

interface Row {
  id: string;
  name: string;
  description: string;
  categories: string;
  tags: string;
  active: boolean;
}

function ActiveToggle(props: Row) {
  const [state, setState] = useState(props.active);

  const toggleApi = useRequester<LocationResource>({
    onSubmit: () => LocationResource.updateOne(props.id, { active: !state }),
    onSuccess: () => setState(!state),
    onError: () =>
      LocationResource.showAlert('Failed to update location', 'error'),
  });

  return (
    <Switch
      onClick={(e) => e.stopPropagation()}
      disabled={toggleApi.loading}
      checked={state}
      onChange={() => toggleApi.submit()}
    />
  );
}

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'description', label: 'Descriptions' },
  { id: 'categories', label: 'Categories' },
  { id: 'tags', label: 'Tags' },
  { id: 'active', label: 'Active', Component: ActiveToggle },
];

const toRow = (ob: LocationResource): Row => {
  const data = ob.data;

  return {
    id: data.id,
    name: data.name,
    description: data.description,
    categories: data.categories.map((c) => c.name).join(', '),
    tags: data.tags?.join(', '),
    active: data.active,
  };
};

export default function LocationsPage() {
  const navigate = useNavigate();

  const locationsApi = useRequester<LocationResource[]>({
    onSubmit: () => LocationResource.fetchMany({ includeInactive: true }),
    onError: console.log,
  });

  const deleteConfirmation = useConfirmation<LocationData>({
    getTitle: (location: LocationData) => `Delete location ${location.name}?`,
    getDescription: (location: LocationData) =>
      `You are about to delete ${location.name}. This action cannot be undone.`,
    onConfirm: async (location: LocationData) => {
      await LocationResource.deleteOne(location.id);
    },
  });

  useEffect(() => {
    locationsApi.submit();
  }, []);

  const rows = LocationResource.list().map(toRow);

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-4">
          <Typography variant="h5" fontWeight="bold">
            Locations
          </Typography>
          {locationsApi.loading && (
            <Box className="flex items-center">
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          onClick={() => navigate('/locations/new')}
        >
          New Location
        </Button>
      </Box>
      <Box className="h-4" />

      {locationsApi.error ? (
        <Alert severity="error">{locationsApi.error}</Alert>
      ) : (
        <Table
          columns={columns}
          rows={rows}
          onRowClick={(row) => navigate(`/locations/${row.id}`)}
          onEdit={(row) => navigate(`/locations/${row.id}/edit`)}
          onDelete={(row) => deleteConfirmation.triggerFor(row as LocationData)}
        />
      )}
    </Box>
  );
}
