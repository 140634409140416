/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Table } from '../../design';
import { useConfirmation, useRequester } from '../../hooks';
import { useEffect, useState } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { TagData, TagResource } from '../../resources';
import TagFormModal from './TagFormModal';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'type', label: 'Type' },
  { id: 'label', label: 'Label' },
  { id: 'labelJp', label: 'Label (JP)' },
  { id: 'description', label: 'Description' },
];

const toRow = (ob: TagResource) => {
  const data = ob.data;

  return {
    id: data.id,
    name: data.name,
    type: data.type,
    label: data.label,
    labelJp: data.labelJp,
    description: data.description,
  };
};

export default function TagsPage() {
  const [showModal, setShowModal] = useState(false);
  const [tagForEdit, setTagForEdit] = useState<TagData>();

  const tagsApi = useRequester<TagResource[]>({
    onSubmit: () => TagResource.fetchMany(),
    onError: console.log,
  });

  useEffect(() => {
    tagsApi.submit();
  }, []);

  const deleteConfirmation = useConfirmation<string>({
    getTitle: (tag) => 'Delete this tag?',
    getDescription: (tag) =>
      `You are about to delete '${tag}'. This action cannot be undone.`,
    onConfirm: async (tag) => {
      await TagResource.deleteOne(tag);
      setShowModal(false);
      tagsApi.submit();
    },
  });

  const tags = TagResource.list();
  const rows = tags.map(toRow);

  const onCloseModal = () => {
    setShowModal(false);
    setTagForEdit(undefined);
  };

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-4">
          <Typography variant="h5" fontWeight="bold">
            Tags
          </Typography>
          {tagsApi.loading && (
            <Box className="flex items-center">
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          onClick={() => setShowModal(true)}
        >
          New Tag
        </Button>
      </Box>
      <Box className="h-4" />

      {tagsApi.error ? (
        <Alert severity="error">{tagsApi.error}</Alert>
      ) : (
        <Table
          columns={columns}
          rows={rows}
          onDelete={(tag) => deleteConfirmation.triggerFor(tag.id as string)}
          onEdit={(tag) => {
            setTagForEdit(tags.find((t) => t.data.id === tag.id)?.data);
            setShowModal(true);
          }}
        />
      )}

      <TagFormModal
        tag={tagForEdit}
        open={showModal}
        onClose={onCloseModal}
        onSuccess={() => {
          onCloseModal();
          tagsApi.submit();
        }}
      />
    </Box>
  );
}
