import BaseResource from './BaseResource';

export interface TagData {
  id: string;
  name: string;
  label: string;
  labelJp: string;
  description: string;
  type: string;
}

class TagResource extends BaseResource<TagData> {
  static get storeName(): string {
    return 'TagResource';
  }

  static get endpoint(): string {
    return `/${this.scope}/tags`;
  }

  static toObject(raw: any): TagResource {
    raw.id = raw.name;
    return new TagResource(raw as TagData);
  }
}

export default TagResource;
