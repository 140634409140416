import BaseResource from './BaseResource';

export interface MediaData {
  id: string;
  title: string;
  logo: string;
  updatedAt: string;
  parser: string;
  hasApiKey: boolean;
  url: string;
}

export interface ExternalArticle {
  id: string;
  title: string;
  titleJp: string;
  audio: string;
  content: string;
  contentJp: string;
  publishedAt: string;
  image: string;
}

class MediaResource extends BaseResource<MediaData> {
  static get storeName(): string {
    return 'MediaResource';
  }

  static get endpoint(): string {
    return `/media`;
  }

  static toObject(raw: any): MediaResource {
    return new MediaResource(raw as MediaData);
  }

  static fetchExternalArticles(mediaId: string): Promise<ExternalArticle[]> {
    return this.http.get(`/media/${mediaId}/news/external`);
  }

  static fetchExternalArticleById(
    mediaId: string,
    articleId: string
  ): Promise<ExternalArticle> {
    return this.http.get(`/media/${mediaId}/news/external/${articleId}`);
  }
}

export default MediaResource;
