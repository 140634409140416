/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequester } from '../../hooks';
import ArticleForm from './ArticleForm';
import { ArticleData, ArticleResource } from '../../resources';
import ExternalArticleModal from './ExternalArticleModal';

export default function ArticleEditPage() {
  // Used for the external article modal
  const [extArticleMedia, setExtArticleMedia] = useState<string | undefined>();
  const [data, setData] = useState<ArticleData>();

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const fetchArticle = useRequester<ArticleResource>({
    onSubmit: (id) => ArticleResource.fetchOne(id),
    onError: console.log,
  });

  const saveArticle = useRequester<ArticleResource>({
    onSubmit: (data: any) =>
      id
        ? ArticleResource.updateOne(id, data)
        : ArticleResource.createOne(data),
    onSuccess: (ob) => navigate(`/articles/${ob.data.id}`),
    onError: console.log,
  });

  useEffect(() => {
    id && fetchArticle.submit(id);
  }, [id]);

  let article;
  if (id) article = ArticleResource.get(id).data as ArticleData;

  if (id && !article) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <ExternalArticleModal
        mediaId={extArticleMedia}
        open
        onClose={() => {
          setData({ media: { id: extArticleMedia } } as ArticleData);
          setExtArticleMedia(undefined);
        }}
        onSelect={(ext) => {
          ext.media = { id: extArticleMedia };
          setData(ext);
          setExtArticleMedia(undefined);
        }}
      />
      {!extArticleMedia && (
        <ArticleForm
          label={id ? 'Edit Article' : 'New Article'}
          onExtMediaSelect={setExtArticleMedia}
          onSubmit={saveArticle.submit}
          saving={saveArticle.loading}
          error={saveArticle.error}
          data={data || article}
        />
      )}
    </Box>
  );
}
