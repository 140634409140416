import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { createContext, useRef, useState } from 'react';

interface IDialogContext {
  setTitle: (title: string) => void;
  setDescription: (description: string) => void;
  setConfirmAction: (action: OnConfirm) => void;
  setOpen: (open: boolean) => void;
}

export const DialogContext = createContext<IDialogContext>({
  setTitle: () => {},
  setDescription: () => {},
  setConfirmAction: () => Promise.resolve(),
  setOpen: () => {},
});

type Props = {
  children: React.ReactNode;
};

type OnConfirm = () => Promise<any>;

export function DialogProvider({ children }: Props) {
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const actionRef = useRef<OnConfirm>();

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    setBusy(true);
    await actionRef.current?.();
    setBusy(false);
    handleClose();
  };

  const setConfirmAction = (action: OnConfirm) => {
    actionRef.current = action;
  };

  return (
    <DialogContext.Provider
      value={{
        setTitle,
        setDescription,
        setConfirmAction,
        setOpen,
      }}
    >
      {children}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pr: 2 }}>
          <Button autoFocus onClick={handleClose} disabled={busy}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            autoFocus
            disabled={busy}
            color="error"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </DialogContext.Provider>
  );
}
