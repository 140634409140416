import {
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import Form from '../form/Form';

type Props = {
  content: string;
  onSave: (content: string) => Promise<void>;
};

export default function EditableText({ content, onSave }: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [busy, setBusy] = useState(false);

  const handleSubmit = (data: any) => {
    setBusy(true);
    onSave(data.content)
      .then(() => setIsEditing(false))
      .finally(() => setBusy(false));
  };

  if (isEditing) {
    return (
      <Form onSubmit={handleSubmit} className="w-full relative" disabled={busy}>
        <Box className="flex flex-row absolute right-3 top-3 z-10">
          <IconButton
            size="small"
            color="primary"
            onClick={() => setIsEditing(false)}
          >
            <CloseIcon />
          </IconButton>
          <IconButton size="small" color="primary" type="submit">
            <CheckIcon />
          </IconButton>
        </Box>

        <TextField
          className="w-full"
          name="content"
          defaultValue={content}
          multiline
        />
      </Form>
    );
  }

  return (
    <Typography whiteSpace="pre-wrap" textAlign="justify" className="relative">
      <IconButton
        size="small"
        color="primary"
        className="right-0 top-2 z-10"
        sx={{ position: 'absolute' }}
        onClick={() => setIsEditing(true)}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      {content}
    </Typography>
  );
}
