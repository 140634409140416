import * as _ from 'lodash';
import BaseResource from './BaseResource';
import MediaResource, { MediaData } from './MediaResource';
import TutorResource, { TutorData } from './TutorResource';
import { StoreActionType } from '../contexts/DataContext';

export interface ArticleData {
  id: string;
  title: string;
  titleJp: string;
  description: string;
  descriptionJp: string;
  content: string;
  contentJp: string;
  image: string;
  tags: string[];
  url: string;
  video: string;
  audio: string;
  media: MediaData;
  publishedAt: string;
  tutor: TutorData;
}

class ArticleResource extends BaseResource<ArticleData> {
  static get storeName(): string {
    return 'ArticleResource';
  }

  static get endpoint(): string {
    return '/news';
  }

  static toObject(raw: any): ArticleResource {
    if (new Date(raw.publishedAt).getFullYear() >= 3000) {
      raw.publishedAt = null;
    }

    return new ArticleResource(raw as ArticleData);
  }

  static async createOne(data: any): Promise<ArticleResource> {
    const ob = await super.createOne(data);
    if (!ob.data.audio) {
      await this.generateAudio(ob.data.id).catch(console.error);
    }
    return ob;
  }

  static async generateAudio(id: string): Promise<string> {
    try {
      const res = await this.http.post(`/news/${id}/narration/generate`);
      const audio = res.location;
      await this.updateOne(id, { audio });
      return audio;
    } catch (e: any) {
      this.showAlert('Failed to generate audio: ' + e.message, 'error');
      console.error(e);
      throw e;
    }
  }

  protected static upsertAndReturn(raw: any) {
    const article: ArticleResource = super.upsertAndReturn(raw);

    if (article.data.media) {
      const media = MediaResource.toObject(article.data.media);
      BaseResource.store.dispatch({
        type: StoreActionType.Upsert,
        target: MediaResource.storeName,
        payload: media.data,
      });
    }

    if (article.data.tutor) {
      const tutor = TutorResource.toObject(article.data.tutor);
      BaseResource.store.dispatch({
        type: StoreActionType.Upsert,
        target: TutorResource.storeName,
        payload: tutor.data,
      });
    }

    return article;
  }

  protected static upsertManyAndReturn(raw: any[]) {
    const articles = super.upsertManyAndReturn(raw);

    const media = articles
      .filter((article: ArticleResource) => article.data.media)
      .map((article: ArticleResource) =>
        MediaResource.toObject(article.data.media)
      )
      .filter(Boolean);

    const tutors = articles
      .filter((article: ArticleResource) => article.data.tutor)
      .map((article: ArticleResource) =>
        TutorResource.toObject(article.data.tutor)
      );

    BaseResource.store.dispatch({
      type: StoreActionType.UpsertMany,
      target: MediaResource.storeName,
      payload: _.uniqBy(
        media.map((item: any) => item.data),
        'id'
      ),
    });

    BaseResource.store.dispatch({
      type: StoreActionType.UpsertMany,
      target: TutorResource.storeName,
      payload: _.uniqBy(
        tutors.map((item: any) => item.data),
        'id'
      ),
    });

    return articles;
  }
}

export default ArticleResource;
