import { Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import * as _ from 'lodash';
import { useMemo } from 'react';

type Props = {
  onChange?: (value: string) => void;
};

export default function Searchbar({ onChange }: Props) {
  const debouncedOnChange = useMemo(() => {
    if (!onChange) return undefined;

    return _.debounce(onChange, 500);
  }, [onChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedOnChange?.(event.target.value);
  };

  return (
    <TextField
      label="Search"
      variant="outlined"
      size="small"
      fullWidth
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
