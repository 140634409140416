import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Pending as PendingIcon,
} from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { ArticleResource, DictionaryResource } from '../../resources';
import { useForceRerender } from '../../hooks';
import * as async from 'async';

type Props = {
  articleId: string;
  open: boolean;
  onClose: () => void;
};

export default function ArticleWordListModal({
  open,
  articleId,
  onClose,
}: Props) {
  const rerender = useForceRerender();

  const [loading, setLoading] = useState(false);
  const article = ArticleResource.get(articleId)?.data;
  const text = [article.data, article.description, article.content].join(' ');

  const words = useMemo(() => {
    const words = text
      .split(/[^a-zA-Z0-9-]+/)
      .map((word) => word.toLowerCase().replace(/^-+|-+$/g, ''))
      .filter((word) => word.length > 1);
    return Array.from(new Set(words));
  }, [text]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      async
        .eachLimit(
          words.filter((word) => !DictionaryResource.get(word).data),
          10,
          async (word, cb) => {
            await DictionaryResource.fetchOne(word).catch(console.error);
            rerender();
            cb();
          }
        )
        .finally(() => setLoading(false));
    }
  }, [open]);

  const exists = (
    <CheckCircleIcon fontSize="inherit" color="success" className="mr-1" />
  );
  const missing = (
    <PendingIcon fontSize="inherit" color="disabled" className="mr-1" />
  );

  if (!article) return null;

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="bg-white absolute top-12 right-12 bottom-12 left-12 p-8 gap-4 flex flex-col">
        <Box className="flex justify-between">
          <Box className="flex flex-row">
            <Typography variant="h5" fontWeight={500}>
              Word List
            </Typography>
            {loading && (
              <Box className="flex items-center ml-2">
                <CircularProgress size={18} />
              </Box>
            )}
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Box className="grid grid-cols-6 gap-1 flex overflow-auto">
          {words.map((word, index) => (
            <Typography key={index}>
              {DictionaryResource.get(word).data ? exists : missing}
              {word}
            </Typography>
          ))}
        </Box>
      </Box>
    </Modal>
  );
}
