/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequester } from '../../hooks';
import TutorResource, { TutorData } from '../../resources/TutorResource';
import TutorForm from './TutorForm';

export default function TutorEditPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const fetchTutor = useRequester<TutorResource>({
    onSubmit: (id) => TutorResource.fetchOne(id),
    onError: console.log,
  });

  const saveTutor = useRequester<TutorResource>({
    onSubmit: (data: any) =>
      id ? TutorResource.updateOne(id, data) : TutorResource.createOne(data),
    onSuccess: (ob) => navigate(`/tutors/${ob.data.id}`),
    onError: console.log,
  });

  useEffect(() => {
    id && fetchTutor.submit(id);
  }, [id]);

  let tutor;
  if (id) tutor = TutorResource.get(id).data as TutorData;

  if (id && !tutor) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <TutorForm
        label={id ? 'Edit Tutor' : 'New Tutor'}
        data={tutor}
        onSubmit={saveTutor.submit}
        saving={saveTutor.loading}
        error={saveTutor.error}
      />
    </Box>
  );
}
