/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfirmation, useRequester } from '../../hooks';
import TutorResource, { TutorData } from '../../resources/TutorResource';
import { useEffect } from 'react';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

export default function TutorPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const deleteConfirmation = useConfirmation<TutorData>({
    getTitle: (tutor: TutorData) => `Delete tutor ${tutor.name}?`,
    getDescription: (tutor: TutorData) =>
      `You are about to delete ${tutor.name}. This action cannot be undone.`,
    onConfirm: async (tutor: TutorData) => {
      await TutorResource.deleteOne(tutor.id);
      navigate('/tutors');
    },
  });

  const fetchTutor = useRequester<TutorResource>({
    onSubmit: (id) => TutorResource.fetchOne(id),
    onError: console.log,
  });

  useEffect(() => {
    fetchTutor.submit(id);
  }, [id]);

  if (!id) return <Alert severity="error">No tutor ID provided</Alert>;
  const tutor = TutorResource.get(id).data as TutorData;
  if (!tutor) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        {fetchTutor.error ? (
          <Alert severity="error">{fetchTutor.error}</Alert>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  return (
    <Box className="flex flex-col gap-4">
      <Box className="flex flex-row gap-4">
        <Avatar
          alt={tutor.name}
          src={tutor.avatar}
          sx={{ height: 120, width: 120 }}
        />
        <Box>
          <Box className="flex flex-row gap-2">
            <Typography variant="h5" fontWeight="bold">
              {tutor.name}
            </Typography>
            <Typography variant="h5">({tutor.identification})</Typography>
            <IconButton
              size="small"
              color="primary"
              onClick={() => navigate(`/tutors/${id}/edit`)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              color="error"
              sx={{ ml: -1 }}
              onClick={() => deleteConfirmation.triggerFor(tutor)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
          <Typography>{tutor.intro}</Typography>
          <Box className="flex gap-2 mt-2">
            {tutor.tags.map((tag) => (
              <Chip key={tag} label={tag} variant="outlined" color="primary" />
            ))}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box className="flex flex-col gap-1">
        <Typography variant="h6" fontWeight="bold">
          Bio
        </Typography>
        <Typography>{tutor.bio}</Typography>
      </Box>
      <Divider />
      <Box className="flex flex-col gap-1">
        <Typography variant="h6" fontWeight="bold">
          Context Prompts
        </Typography>

        <Box className="flex flex-col gap-4 whitespace-pre-wrap">
          <Box>
            <Typography variant="subtitle2">Beginner</Typography>
            <Typography textAlign="justify" className="whitespace-pre-wrap">
              {tutor.contextPrompt.beginner}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Intermediate</Typography>
            <Typography textAlign="justify">
              {tutor.contextPrompt.intermediate}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Advanced</Typography>
            <Typography textAlign="justify">
              {tutor.contextPrompt.advanced}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className="flex flex-col gap-1">
        <Typography variant="h6" fontWeight="bold">
          Other Prompts
        </Typography>

        <Box className="flex flex-col gap-4 whitespace-pre-wrap">
          <Box>
            <Typography variant="subtitle2">Mission Generator</Typography>
            <Typography textAlign="justify" className="whitespace-pre-wrap">
              {tutor.missionPrompt}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Hint Generator</Typography>
            <Typography textAlign="justify">{tutor.hintPrompt}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              General Feedback Generator
            </Typography>
            <Typography textAlign="justify">
              {tutor.generalFeedbackPrompt}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              General Feedback Translator
            </Typography>
            <Typography textAlign="justify">
              {TutorResource.getPrompt(tutor, 'generalFeedbackTranslator')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              Grammar Feedback Generator
            </Typography>
            <Typography textAlign="justify">
              {tutor.grammarFeedbackPrompt}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              Grammar Feedback Translator
            </Typography>
            <Typography textAlign="justify">
              {TutorResource.getPrompt(tutor, 'grammarFeedbackTranslator')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Summary Generator</Typography>
            <Typography textAlign="justify">{tutor.summaryPrompt}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Summary Translator</Typography>
            <Typography textAlign="justify">
              {TutorResource.getPrompt(tutor, 'summaryTranslator')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              Word Explanation Generator
            </Typography>
            <Typography textAlign="justify">
              {TutorResource.getPrompt(tutor, 'wordExplanationEn')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              Word Explanation Generator (JP)
            </Typography>
            <Typography textAlign="justify">
              {TutorResource.getPrompt(tutor, 'wordExplanationJp')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Word Usage Generator</Typography>
            <Typography textAlign="justify">
              {TutorResource.getPrompt(tutor, 'wordUsageEn')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              Word Usage Generator (JP)
            </Typography>
            <Typography textAlign="justify">
              {TutorResource.getPrompt(tutor, 'wordUsageJp')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              Word Conversation Starter
            </Typography>
            <Typography textAlign="justify">
              {TutorResource.getPrompt(tutor, 'wordConversation')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              World Travel Mode (beginner)
            </Typography>
            <Typography textAlign="justify">
              {TutorResource.getPrompt(tutor, 'travelMode', 'beginner')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              World Travel Mode (intermediate)
            </Typography>
            <Typography textAlign="justify">
              {TutorResource.getPrompt(tutor, 'travelMode', 'intermediate')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              World Travel Mode (advanced)
            </Typography>
            <Typography textAlign="justify">
              {TutorResource.getPrompt(tutor, 'travelMode', 'advanced')}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Box className="flex flex-col gap-1">
        <Typography variant="h6" fontWeight="bold">
          Voices
        </Typography>
        <Box className="flex flex-col gap-2">
          <Box>
            <Typography variant="subtitle2">English</Typography>
            <Box className="flex flex-col">
              <Typography>Service: {tutor.voice.service}</Typography>
              <Typography>Code: {tutor.voice.code}</Typography>
              <Typography>Accent: {tutor.voice.accent}</Typography>
              <Typography>
                Speed: {tutor.voice.speed.beginner}/
                {tutor.voice.speed.intermediate}/{tutor.voice.speed.advanced}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle2">Japanese</Typography>
            <Box className="flex flex-col">
              <Typography>Service: {tutor.voiceJp.service}</Typography>
              <Typography>Code: {tutor.voiceJp.code}</Typography>
              <Typography>Accent: {tutor.voiceJp.accent}</Typography>
              <Typography>
                Speed: {tutor.voiceJp.speed.beginner}/
                {tutor.voiceJp.speed.intermediate}/
                {tutor.voiceJp.speed.advanced}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}
