import { useRef, useState } from 'react';
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { SaveRounded as SaveIcon, Source } from '@mui/icons-material';
import { Form } from '../../design';
import { useForceRerender } from '../../hooks';
import { MediaData, MediaResource } from '../../resources';

type Props = {
  onSubmit: (data: any) => void;
  saving?: boolean;
  data?: MediaData;
  error?: string;
  label: string;
};

export default function MediaForm({
  onSubmit,
  saving,
  data,
  error,
  label,
}: Props) {
  const forceRender = useForceRerender();
  const logoRef = useRef<HTMLInputElement>(null);
  const image = logoRef.current?.files?.[0];
  const [parser, setParser] = useState(data?.parser);
  const [uploading, setUploading] = useState(false);

  const handleSubmit = async (data: any) => {
    setUploading(true);

    if (!data.parser) delete data.parser;
    if (!data.apiKey) delete data.apiKey;

    // If the URL contains :*****@ substring, it means the URL is not changed
    // and should not be updated.
    if (data.url?.includes(':*****@')) delete data.url;

    const image = logoRef.current?.files?.[0];
    if (image) data.logo = await MediaResource.upload(image, 'image');

    onSubmit(data);
    setUploading(false);
  };

  const busy = uploading || saving;

  return (
    <Form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4"
      disabled={busy}
    >
      <Typography variant="h6" sx={{ lineHeight: 1 }}>
        {label}
      </Typography>
      <Box className="flex gap-4">
        <Box>
          <label className="hover:cursor-pointer">
            <Avatar
              sx={{
                height: 56,
                width: 56,
                border: '1px dotted',
                borderRadius: 1,
              }}
              variant="square"
              src={image ? URL.createObjectURL(image) : data?.logo}
            >
              <Source />
            </Avatar>

            <input
              ref={logoRef}
              hidden
              type="file"
              accept="image/*"
              onChange={() => setTimeout(forceRender, 100)}
            />
          </label>
        </Box>
        <Box className="grid grid-cols-3 flex-grow gap-4">
          <TextField
            required
            name="title"
            label="Title"
            defaultValue={data?.title}
          />
          <FormControl>
            <InputLabel id="select-parser">Parser</InputLabel>
            <Select
              labelId="select-parser"
              name="parser"
              defaultValue={data?.parser}
              label="Parser"
              onChange={(e) => setParser(e.target.value as string)}
            >
              <MenuItem value="">-</MenuItem>
              <MenuItem value="nikkei">Nikkei</MenuItem>
              <MenuItem value="rss">RSS</MenuItem>
            </Select>
          </FormControl>

          {parser === 'rss' ? (
            <TextField name="url" label="Feed URL" defaultValue={data?.url} />
          ) : (
            parser && <TextField name="apiKey" label="Api Key" />
          )}
        </Box>
      </Box>
      <Divider />
      <Box className="flex flex-row gap-4">
        <Button
          size="large"
          startIcon={
            busy ? (
              <CircularProgress size={24} />
            ) : (
              <SaveIcon sx={{ height: 24, width: 24 }} />
            )
          }
          type="submit"
          variant="contained"
          disabled={busy}
        >
          Save Media
        </Button>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
    </Form>
  );
}
