import { useCallback, useState } from 'react';

type Props<T> = {
  onSubmit: (data: any) => Promise<T>;
  onSuccess?: (data: T) => void;
  onError?: (error: any) => void;
};

type Requester = {
  submit: (data?: any) => Promise<void>;
  loading: boolean;
  error: string;
};

function useRequester<T>(props: Props<T>): Requester {
  const [reqCount, setReqCount] = useState(0);
  const [error, setError] = useState<string>('');
  const { onSubmit, onSuccess, onError } = props;

  const submit = useCallback(
    async (data?: any): Promise<void> => {
      setReqCount((count) => count + 1);
      setError('');

      try {
        const res: T = await onSubmit(data);
        onSuccess && onSuccess(res);
        setError('');
      } catch (error: any) {
        const msg = error?.response?.data?.message;
        if (typeof msg === 'string') setError(msg);
        else setError(error?.response?.data?.message?.[0] || 'API Error');
        onError && onError(error);
      }

      setReqCount((count) => count - 1);
    },
    [onSubmit, onSuccess, onError]
  );

  return {
    submit,
    loading: reqCount > 0,
    error: reqCount > 0 ? '' : error,
  };
}

export default useRequester;
