import classNames from 'classnames';

type Props = {
  onSubmit: (data: any) => void;
  children: React.ReactNode;
  className?: string;
  names?: string[];
  disabled?: boolean;
};

function Form({ onSubmit, children, className, disabled }: Props) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data: any = {};

    // @ts-ignore
    for (const element of event.target.elements) {
      const name = element.name;
      if (name) data[name] = element.value;
    }

    onSubmit(data);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames(className, {
        'pointer-events-none': disabled,
        'opacity-50': disabled,
      })}
    >
      {children}
    </form>
  );
}

export default Form;
