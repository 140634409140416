/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Table } from '../../design';
import { useRequester } from '../../hooks';
import { useEffect } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { TutorResource } from '../../resources';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'voiceEn', label: 'Voice (EN)' },
  { id: 'voiceJp', label: 'Voice (JP)' },
  { id: 'tags', label: 'Tags' },
];

const toRow = (ob: TutorResource) => {
  const data = ob.data;

  return {
    id: data.id,
    name: `${data.name} (${data.identification})`,
    voiceEn: data.voice.code,
    voiceJp: data.voiceJp.code,
    tags: data.tags?.join(', '),
  };
};

export default function TutorsPage() {
  const navigate = useNavigate();

  const tutorsApi = useRequester<TutorResource[]>({
    onSubmit: () => TutorResource.fetchMany({ includeSystem: true }),
    onError: console.log,
  });

  useEffect(() => {
    tutorsApi.submit();
  }, []);

  const rows = TutorResource.list().map(toRow);

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-4">
          <Typography variant="h5" fontWeight="bold">
            AI Tutors
          </Typography>
          {tutorsApi.loading && (
            <Box className="flex items-center">
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          onClick={() => navigate('/tutors/new')}
        >
          New Tutor
        </Button>
      </Box>
      <Box className="h-4" />

      {tutorsApi.error ? (
        <Alert severity="error">{tutorsApi.error}</Alert>
      ) : (
        <Table
          columns={columns}
          rows={rows}
          onRowClick={(row) => navigate(`/tutors/${row.id}`)}
          onEdit={(row) => navigate(`/tutors/${row.id}/edit`)}
        />
      )}
    </Box>
  );
}
