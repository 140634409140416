import { Box, Button, Typography } from '@mui/material';
import { BaseResource } from '../../resources';
import { useContext } from 'react';
import { AuthContext } from '../../contexts';

function HomePage() {
  const scope = BaseResource.scope === 'vr' ? 'VR' : 'Lite';
  const { user } = useContext(AuthContext);

  return (
    <Box
      className="flex flex-col flex-grow justify-center items-center"
      sx={{ height: 'calc(100vh - 12rem)' }}
    >
      <Typography variant="h4">Smart Tutor {scope} Admin Console</Typography>
      <Typography variant="h5" color="text.secondary">
        You are logged in as {user?.data.username}
      </Typography>
      <Button
        sx={{ mt: 2 }}
        onClick={() => BaseResource.toggleScope()}
        variant="contained"
      >
        Switch to {BaseResource.scope === 'vr' ? 'Lite' : 'VR'}
      </Button>
    </Box>
  );
}

export default HomePage;
