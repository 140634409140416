import { Navigate, useLocation, Outlet } from 'react-router-dom';
import cookie from 'js-cookie';

export default function PrivateRouteGate() {
  const location = useLocation();

  if (!cookie.get('token')) return <Navigate to="/login" />;

  if (location.pathname === '/login') return <Navigate to="/articles" />;

  return <Outlet />;
}
