import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

type Column = {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
  Component?: (value: any) => JSX.Element;
};

type Row = {
  id: string | number;
  [key: string]: any;
};

type Props = {
  columns: Column[];
  rows: Row[];
  onDelete?: (row: Row) => void;
  onEdit?: (row: Row) => void;
  onRowClick?: (row: Row) => void;
};

export default function BasicTable({
  columns,
  rows,
  onRowClick,
  onDelete,
  onEdit,
}: Props) {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
            {(onEdit || onDelete) && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '&:hover': onRowClick
                  ? {
                      backgroundColor: '#eceff1',
                      cursor: 'pointer',
                    }
                  : {},
              }}
            >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  onClick={onRowClick && (() => onRowClick(row))}
                >
                  {column.Component ? (
                    <column.Component {...row} />
                  ) : (
                    row[column.id]
                  )}
                </TableCell>
              ))}
              {(onDelete || onEdit) && (
                <TableCell align="right">
                  {onEdit && (
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => onEdit(row)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )}
                  {onDelete && (
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => onDelete(row)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
