/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequester } from '../../hooks';
import LocationForm from './LocationForm';
import { LocationData, LocationResource } from '../../resources';

export default function LocationEditPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const fetchLocation = useRequester<LocationResource>({
    onSubmit: (id) => LocationResource.fetchOne(id),
    onError: console.log,
  });

  const saveLocation = useRequester<LocationResource>({
    onSubmit: (data: any) =>
      id
        ? LocationResource.updateOne(id, data)
        : LocationResource.createOne(data),
    onSuccess: (ob) => navigate(`/locations/${ob.data.id}`),
    onError: console.log,
  });

  useEffect(() => {
    id && fetchLocation.submit(id);
  }, [id]);

  let location;
  if (id) location = LocationResource.get(id).data as LocationData;

  if (id && !location) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <LocationForm
        label={id ? 'Edit Location' : 'New Location'}
        data={location}
        onSubmit={saveLocation.submit}
        saving={saveLocation.loading}
        error={saveLocation.error}
      />
    </Box>
  );
}
