import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useConfirmation, useRequester } from '../../hooks';
import { useEffect, useState } from 'react';
import { Autocomplete, EditableText } from '../../design';
import { DictionaryData, DictionaryResource } from '../../resources';
import { Delete as DeleteIcon } from '@mui/icons-material';

export default function DictionaryPage() {
  const [word, setWord] = useState<string | null>();

  const fetchWord = useRequester<DictionaryResource>({
    onSubmit: (word) => DictionaryResource.fetchOne(word),
    onError: console.log,
  });

  const saveWord = useRequester<DictionaryResource>({
    onSubmit: (data: any) => {
      if (!word) return Promise.reject('Word is not set.');
      return DictionaryResource.updateOne(word, data);
    },
    onError: console.log,
  });

  const deleteConfirmation = useConfirmation<DictionaryData>({
    getTitle: (dict: DictionaryData) =>
      `Remove "${dict.word}" from dictionary?`,
    getDescription: (dict: DictionaryData) =>
      `You are about to delete the word "${dict.word}". If you search for this word again, it will be regenerated.`,
    onConfirm: async (dict: DictionaryData) => {
      await DictionaryResource.deleteOne(dict.word);
      setWord(null);
    },
  });

  useEffect(() => {
    if (word) fetchWord.submit(word);
  }, [word]);

  const dict = word && DictionaryResource.get(word)?.data;

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-4">
          <Typography variant="h5" fontWeight="bold">
            Dictionary
          </Typography>
        </Box>
      </Box>
      <Box className="h-4" />

      <Box className="flex flex-col gap-4">
        <Box className="grid grid-cols-3 gap-4">
          <Autocomplete
            label="Search a word"
            onChange={setWord}
            disabled={fetchWord.loading}
            getOptions={async (val) => {
              if (!val) return [];

              val = val.trim().toLowerCase();

              const data = await DictionaryResource.fetchMany({
                q: val,
                limit: 6,
              });

              const options: any = data.map((word) => ({
                label: word.data.word,
                value: word.data.word,
              }));

              const exists = options.find(
                (opt: any) => opt.value.toLowerCase() === val
              );

              if (!exists) {
                options.unshift({
                  inputLabel: `Generate "${val}"`,
                  label: val,
                  value: val,
                });
              }

              return options;
            }}
          />
          <Box className="flex flex-col justify-center">
            {fetchWord.loading ? (
              <CircularProgress className="ml-1" size={20} />
            ) : (
              dict && (
                <Box>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => deleteConfirmation.triggerFor(dict)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )
            )}
          </Box>
        </Box>
        <Divider />
        {dict && (
          <Box className="grid grid-cols-2 gap-8">
            <EditableText
              content={dict?.explanation}
              onSave={(content) => saveWord.submit({ explanation: content })}
            />
            <EditableText
              content={dict?.explanationJp}
              onSave={(content) => saveWord.submit({ explanationJp: content })}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
