import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { AuthContext } from '../contexts';
import { useContext, useState } from 'react';
import { Logout } from '@mui/icons-material';
import { UserResource } from '../resources';

export default function AvatarMenu() {
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={handleClick} size="small">
        <Avatar sx={{ bgcolor: 'secondary.main', fontSize: 15 }}>
          {user?.data.firstName.slice(0, 1).toUpperCase()}
          {user?.data.lastName.slice(0, 1).toUpperCase()}
        </Avatar>
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Box className="px-4 py-2">
          <Typography fontWeight="bold">
            {user?.data.firstName} {user?.data.lastName}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {user?.data.email}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={() => UserResource.logout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        <Divider sx={{ my: 1 }} />
      </Menu>
    </Box>
  );
}
