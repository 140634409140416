import { useState } from 'react';

type Severity = 'info' | 'warning' | 'error' | 'success';

type Snack = {
  msg: string;
  severity: Severity;
};

export interface ISnackbar {
  showAlert: (msg: string, severity?: Severity) => void;
  closeAlert: () => void;
  open: boolean;
  snack: Snack;
}

let timeout: NodeJS.Timeout;

export default function useSnackbar(): ISnackbar {
  const [open, setOpen] = useState(false);
  const [snack, setSnack] = useState<Snack>({ msg: '', severity: 'info' });

  const showAlert = (msg: string, severity: Severity = 'info') => {
    clearTimeout(timeout);
    setSnack({ msg, severity });
    setOpen(true);
  };

  const closeAlert = () => {
    setOpen(false);
    timeout = setTimeout(() => {
      setSnack({ msg: '', severity: 'info' });
    }, 1000);
  };

  return {
    showAlert,
    closeAlert,
    open,
    snack,
  };
}
