import { useContext } from 'react';
import { DialogContext } from '../contexts';

type Props<T> = {
  onConfirm: (target: T) => Promise<any>;
  getTitle: (target: T) => string;
  getDescription: (target: T) => string;
};

export default function useConfirmation<T>({
  getTitle,
  getDescription,
  onConfirm,
}: Props<T>) {
  const { setTitle, setDescription, setConfirmAction, setOpen } =
    useContext(DialogContext);

  const triggerFor = (target: T) => {
    setTitle(getTitle(target));
    setDescription(getDescription(target));
    setConfirmAction(() => onConfirm(target));
    setOpen(true);
  };

  return {
    triggerFor,
  };
}
