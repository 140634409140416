import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { FileInput, Form } from '../../design';
import { useForceRerender, useRequester } from '../../hooks';
import { useEffect, useState } from 'react';
import { SaveRounded as SaveIcon } from '@mui/icons-material';
import {
  ArticleData,
  BaseResource,
  MediaData,
  MediaResource,
  TagResource,
} from '../../resources';

type Props = {
  onSubmit: (data: any) => void;
  onExtMediaSelect: (mediaId: string) => void;
  saving?: boolean;
  data?: ArticleData;
  error?: string;
  label?: string;
};

export default function ArticleForm({
  label,
  onSubmit,
  saving,
  data,
  error,
  onExtMediaSelect,
}: Props) {
  const forceRender = useForceRerender();
  const [mediaId, setMediaId] = useState<string>(data?.media?.id || '');
  const [image, setImage] = useState<File>();
  const [video, setVideo] = useState<File>();
  const [audio, setAudio] = useState<File>();
  const [uploading, setUploading] = useState(false);

  const fetchMedia = useRequester<MediaResource[]>({
    onSubmit: (id) => MediaResource.fetchMany(id),
    onSuccess: forceRender,
  });

  const fetchTags = useRequester<TagResource[]>({
    onSubmit: () => TagResource.fetchMany(),
    onError: console.log,
  });

  const handleSubmit = async (data: any) => {
    data.publishedAt = data.publishedAt
      ? new Date(data.publishedAt).toISOString()
      : null;

    data.tags = data.tags
      .split(',')
      .filter((tag: string) => tag.trim().length > 0);

    setUploading(true);

    if (video) data.video = await BaseResource.upload(video, 'video');
    if (image) data.image = await BaseResource.upload(image, 'image');
    if (audio) data.audio = await BaseResource.upload(audio, 'audio');

    if (!data.video) delete data.video;
    if (!data.image) delete data.image;
    if (!data.audio) delete data.audio;

    onSubmit(data);
    setUploading(false);
  };

  useEffect(() => {
    fetchMedia.submit();
    fetchTags.submit();
  }, []);

  const media: MediaData | null = mediaId
    ? MediaResource.get(mediaId).data
    : null;

  const tags = TagResource.list().map((tag) => tag.data);
  const busy = uploading || saving;

  return (
    <Form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4"
      disabled={busy}
    >
      <Box className="flex flex-col gap-4">
        <Typography variant="h6" sx={{ lineHeight: 1 }}>
          {label}
        </Typography>
        <Box className="flex gap-2">
          <FormControl sx={{ width: 200 }} required>
            <InputLabel id="select-media" required>
              Media
            </InputLabel>
            <Select
              required
              labelId="select-media"
              name="mediaId"
              label="Media"
              value={mediaId || data?.media?.id || ''}
              onChange={(e) => setMediaId(e.target.value as string)}
            >
              {MediaResource.list().map((media) => (
                <MenuItem key={media.data.id} value={media.data.id}>
                  {media.data.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(media?.hasApiKey || media?.url) && (
            <Button size="large" onClick={() => onExtMediaSelect(media.id)}>
              Select from API
            </Button>
          )}
        </Box>
      </Box>
      <Divider />
      <Box className="grid grid-cols-4 gap-4">
        <Box className="flex flex-col gap-4 col-span-3">
          <Typography variant="subtitle1" fontWeight={500}>
            Base Content
          </Typography>
          <TextField
            required
            label="Title (English)"
            name="title"
            defaultValue={data?.title}
          />
          <TextField
            label="Title (Japanese)"
            name="titleJp"
            defaultValue={data?.titleJp}
          />
          <TextField
            required
            label="Description (English)"
            name="description"
            defaultValue={data?.description}
            multiline
          />
          <TextField
            label="Description (Japanese)"
            name="descriptionJp"
            defaultValue={data?.descriptionJp}
            multiline
          />

          <TextField
            required
            label="Full text (English)"
            name="content"
            defaultValue={data?.content}
            multiline
          />
          <TextField
            label="Full text (Japanese)"
            name="contentJp"
            defaultValue={data?.contentJp}
            multiline
          />
        </Box>
        <Box className="flex flex-col gap-4 col-span-1">
          <Typography variant="subtitle1" fontWeight={500}>
            Others
          </Typography>
          <TextField
            label="Publish Date"
            InputLabelProps={{ shrink: true }}
            type="datetime-local"
            name="publishedAt"
            defaultValue={
              data?.publishedAt
                ? moment(data.publishedAt).format('YYYY-MM-DDTHH:mm')
                : null
            }
          />
          <FormControl fullWidth>
            <InputLabel id="select-tags">Tags</InputLabel>
            <Select
              labelId="select-tags"
              multiple
              name="tags"
              defaultValue={data?.tags || []}
              label="Tags"
            >
              {tags.map((tag) => (
                <MenuItem key={tag.name} value={tag.name}>
                  {tag.label}
                </MenuItem>
              ))}
              {
                // Account for the tags that are deleted from the system but
                // still assigned to the article object
                data?.tags
                  ?.filter((tag) => !tags.find((t) => t.name === tag))
                  .map((tag) => (
                    <MenuItem key={tag} value={tag}>
                      {tag}
                    </MenuItem>
                  ))
              }
            </Select>
          </FormControl>
          <FileInput
            label="Video"
            name="video"
            accept="video/*"
            defaultValue={data?.video}
            onSelect={setVideo}
          />
          <FileInput
            label="Image"
            name="image"
            accept="image/*"
            defaultValue={data?.image}
            onSelect={setImage}
          />
          <FileInput
            label="Audio"
            name="audio"
            accept="audio/*"
            defaultValue={data?.audio}
            onSelect={setAudio}
          />
        </Box>
      </Box>
      <Divider />
      <Box className="flex flex-row gap-4">
        <Button
          size="large"
          startIcon={
            busy ? (
              <CircularProgress size={24} />
            ) : (
              <SaveIcon sx={{ height: 24, width: 24 }} />
            )
          }
          type="submit"
          variant="contained"
          disabled={busy}
        >
          Save Article
        </Button>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
    </Form>
  );
}
