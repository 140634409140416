/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  CircularProgress,
  CssBaseline,
  Snackbar,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Appbar from './Appbar';
import Drawer from './Drawer';
import PrivateRouteGate from './PrivateRouteGate';
import { BaseResource, UserResource } from '../resources';
import { useRequester, useSnackbar } from '../hooks';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

type Props = {
  routes: {
    title?: string;
    path: string;
    icon?: JSX.Element;
    Component: () => JSX.Element;
  }[];
};

type DrawerItem = {
  title: string;
  path: string;
  icon: JSX.Element;
  Component: () => JSX.Element;
};

export default function Layout({ routes }: Props) {
  const [open, setOpen] = useState(false);
  const snackbar = useSnackbar();
  const usersApi = useRequester<UserResource[]>({
    onSubmit: () => UserResource.fetchMany(),
    onError: console.log,
  });

  useEffect(() => {
    // Preload all users
    usersApi.submit();
    BaseResource.setSnackbar(snackbar);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawerItems = routes.filter(
    (route) => route.title && route.path
  ) as DrawerItem[];

  if (usersApi.loading) {
    return (
      <Box className="flex justify-center items-center h-screen">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Appbar open={open} handleDrawerOpen={handleDrawerOpen} />
      <Drawer open={open} onClose={handleDrawerClose} items={drawerItems} />

      {/* Not putting the 50% gives unusual affect. Investigate later. */}
      <Box component="main" sx={{ p: 3, width: '50%', flexGrow: 1 }}>
        <DrawerHeader />
        <Routes>
          <Route path="/" Component={PrivateRouteGate}>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.Component />}
              />
            ))}
            <Route path="*" element={<div>404</div>} />
          </Route>
        </Routes>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={snackbar.closeAlert}
      >
        <Alert
          severity={snackbar.snack.severity}
          variant="filled"
          sx={{ width: '100%' }}
          onClose={snackbar.closeAlert}
        >
          {snackbar.snack.msg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
