import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useRequester } from '../../hooks';
import { useEffect } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { fromNow } from '../../utils';
import { ArticleData, ArticleResource, MediaResource } from '../../resources';

function ArticleCard(props: { article: ArticleData }) {
  const { article } = props;

  return (
    <>
      <Divider />
      <Box>
        <Typography variant="body2" color="text.secondary">
          From{' '}
          <Link to={`/articles?mediaId=${article.media.id}`}>
            <Box component="span" sx={{ color: 'primary.main' }}>
              {article.media.title}
            </Box>
          </Link>{' '}
          {fromNow(article.publishedAt)}&nbsp; &bull; &nbsp;
          {article.tags.map((tag) => (
            <Chip
              component="span"
              key={tag}
              label={tag}
              variant="outlined"
              color="primary"
              size="small"
            />
          ))}
        </Typography>
        <Link to={`/articles/${article.id}`}>
          <Typography variant="h6">{article.title}</Typography>
        </Link>
        <Typography noWrap variant="body1">
          {article.content}
        </Typography>
      </Box>
    </>
  );
}

export default function ArticlesPage() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const mediaId = params.get('mediaId');

  const articleApi = useRequester<ArticleResource[]>({
    onSubmit: (q) => ArticleResource.fetchMany(q),
    onError: console.log,
  });

  const mediaApi = useRequester<MediaResource[]>({
    onSubmit: () => MediaResource.fetchMany(),
    onError: console.log,
  });

  useEffect(() => {
    mediaApi.submit();
  }, []);

  useEffect(() => {
    articleApi.submit({ mediaId, includeUnreleased: true });
  }, [mediaId]);

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-4">
          <Typography variant="h5" fontWeight="bold">
            Articles
          </Typography>
          {articleApi.loading && (
            <Box className="flex items-center">
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
        <Box className="flex gap-4">
          <FormControl size="small">
            <InputLabel id="select-media">Filter by Media</InputLabel>
            <Select
              labelId="select-media"
              label="Filter by Media"
              size="small"
              sx={{ minWidth: 160 }}
              value={mediaId || ''}
              onChange={(e) => {
                const mediaId = e.target.value as string;
                mediaId ? setParams({ mediaId }) : setParams({});
              }}
            >
              <MenuItem value="">Any</MenuItem>
              {MediaResource.list().map((media) => (
                <MenuItem key={media.data.id} value={media.data.id}>
                  {media.data.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            endIcon={<AddIcon />}
            onClick={() => navigate('/articles/new')}
          >
            New Article
          </Button>
        </Box>
      </Box>
      <Box className="h-4" />

      {articleApi.error ? (
        <Alert severity="error">{articleApi.error}</Alert>
      ) : (
        <Box className="flex flex-col gap-4">
          {ArticleResource.list().map((article) => (
            <ArticleCard key={article.data.id} article={article.data} />
          ))}
        </Box>
      )}
    </Box>
  );
}
