import cookie from 'js-cookie';
import BaseResource from './BaseResource';

export interface UserData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  createdAt: string;
  superuser: boolean;
}

class UserResource extends BaseResource<UserData> {
  static get storeName() {
    return 'UserResource';
  }

  static get endpoint() {
    return '/users';
  }

  static async login(
    username: string,
    password: string
  ): Promise<UserResource> {
    const userRaw = await this.http.post(
      '/auth/login',
      {},
      { username, password }
    );

    const user = this.toObject(userRaw);
    user.updateStore();

    cookie.set('token', userRaw.token, { expires: 7 });

    return user;
  }

  static async logout() {
    cookie.remove('token');
    window.location.href = '/login';
    window.location.reload();
  }

  static async refresh(): Promise<UserResource> {
    const userRaw = await this.http.post('/auth/refresh', {});

    const user = this.toObject(userRaw);
    user.updateStore();

    return user;
  }

  static toObject(userRaw: any): UserResource {
    return new UserResource({
      id: userRaw.id || userRaw._id,
      firstName: userRaw.first_name,
      lastName: userRaw.last_name,
      email: userRaw.email,
      username: userRaw.username,
      createdAt: userRaw.created_at,
      superuser: userRaw.superuser,
    } as UserData);
  }
}

export default UserResource;
