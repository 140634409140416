import * as _ from 'lodash';
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { SaveRounded as SaveIcon } from '@mui/icons-material';
import { Form } from '../../design';
import { useForceRerender, useRequester } from '../../hooks';
import {
  BaseResource,
  TagResource,
  TutorData,
  TutorResource,
} from '../../resources';

type Props = {
  onSubmit: (data: any) => void;
  saving?: boolean;
  data?: TutorData;
  error?: string;
  label: string;
};

const makeRequestBody = (data: any) => {
  const voice = {
    service: data.voiceServiceJp,
    code: data.voiceCodeEn,
    accent: data.voiceAccentEn,
    speed: {
      beginner: parseFloat(data.voiceSpeedBeginnerEn),
      intermediate: parseFloat(data.voiceSpeedIntermediateEn),
      advanced: parseFloat(data.voiceSpeedAdvancedEn),
    },
  };

  const voiceJp = {
    service: data.voiceServiceJp,
    code: data.voiceCodeJp,
    accent: data.voiceAccentJp,
    speed: {
      beginner: parseFloat(data.voiceSpeedBeginnerJp),
      intermediate: parseFloat(data.voiceSpeedIntermediateJp),
      advanced: parseFloat(data.voiceSpeedAdvancedJp),
    },
  };

  const contextPrompt = {
    beginner: data.contextPromptBeginner,
    intermediate: data.contextPromptIntermediate,
    advanced: data.contextPromptAdvanced,
  };

  // Todo: refactor to combine all prompts into one object
  const prompts: any = _.pick(data, [
    'wordExplanationEn',
    'wordExplanationJp',
    'wordUsageEn',
    'wordUsageJp',
    'wordConversation',
    'summaryTranslator',
    'generalFeedbackTranslator',
    'grammarFeedbackTranslator',
  ]);

  prompts.travelMode = {
    beginner: data.travelModeBeginner,
    intermediate: data.travelModeIntermediate,
    advanced: data.travelModeAdvanced,
  };

  const body = {
    voice,
    voiceJp,
    contextPrompt,
    prompts,
    tags: data.tags.split(',').filter((tag: string) => tag.trim().length > 0),
    ..._.pick(data, [
      'name',
      'identification',
      'bio',
      'intro',
      'scope',
      'missionPrompt',
      'hintPrompt',
      'summaryPrompt',
      'generalFeedbackPrompt',
      'grammarFeedbackPrompt',
    ]),
  };

  return body;
};

export default function TutorForm({
  label,
  onSubmit,
  saving,
  data,
  error,
}: Props) {
  const forceRender = useForceRerender();
  const logoRef = useRef<HTMLInputElement>(null);
  const image = logoRef.current?.files?.[0];
  const [uploading, setUploading] = useState(false);

  const fetchTags = useRequester<TagResource[]>({
    onSubmit: () => TagResource.fetchMany(),
    onError: console.log,
  });

  useEffect(() => {
    fetchTags.submit();
  }, []); // eslint-disable-line

  const tags = TagResource.list().map((tag) => tag.data);

  const handleSubmit = async (data: TutorData) => {
    setUploading(true);

    let avatar;
    const image = logoRef.current?.files?.[0];
    if (image) avatar = await TutorResource.upload(image, 'image');

    const body = makeRequestBody({ ...data, scope: BaseResource.scope });
    onSubmit({ ...body, avatar });

    setUploading(false);
  };

  const busy = saving || uploading;

  return (
    <Form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4"
      disabled={busy}
    >
      <Typography variant="h6" sx={{ lineHeight: 1 }}>
        {label}
      </Typography>
      <Divider />
      <Typography variant="subtitle2">Basic Info</Typography>
      <Box className="flex">
        <Box className="h-40 w-40 py-2">
          <label className="hover:cursor-pointer">
            {image ? (
              <>
                <Avatar
                  sx={{ height: 130, width: 130 }}
                  src={URL.createObjectURL(image)}
                />
                <Box textAlign="center" sx={{ width: 130, mt: 2 }}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      logoRef.current!.value = '';
                      forceRender();
                    }}
                  >
                    Clear
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Avatar sx={{ height: 130, width: 130 }} src={data?.avatar} />
                <Box textAlign="center" sx={{ width: 130, mt: 2 }}>
                  <Button onClick={() => logoRef.current?.click()}>
                    Select Avatar
                  </Button>
                </Box>
              </>
            )}

            <input
              ref={logoRef}
              hidden
              type="file"
              accept="image/*"
              onChange={() => setTimeout(forceRender, 100)}
            />
          </label>
        </Box>

        <Box className="flex-grow grid grid-cols-3 gap-4">
          <TextField label="Name" name="name" defaultValue={data?.name} />
          <TextField
            label="Identification"
            name="identification"
            defaultValue={data?.identification}
          />
          <FormControl fullWidth disabled={fetchTags.loading}>
            <InputLabel id="select-tags">Tags</InputLabel>
            <Select
              labelId="select-tags"
              multiple
              name="tags"
              defaultValue={data?.tags || []}
              label="Tags"
            >
              {tags.map((tag) => (
                <MenuItem key={tag.name} value={tag.name}>
                  {tag.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Bio"
            name="bio"
            multiline
            className="col-span-3"
            defaultValue={data?.bio}
          />
          <TextField
            label="Intro"
            name="intro"
            multiline
            className="col-span-3"
            defaultValue={data?.intro}
          />
        </Box>
      </Box>

      <Divider />
      <Typography variant="subtitle2">Context Prompts</Typography>
      <Box className="grid grid-cols-2 gap-4">
        <TextField
          label="Beginner"
          name="contextPromptBeginner"
          multiline
          className="col-span-2"
          defaultValue={data?.contextPrompt?.beginner}
        />
        <TextField
          label="Intermediate"
          name="contextPromptIntermediate"
          multiline
          className="col-span-2"
          defaultValue={data?.contextPrompt?.intermediate}
        />
        <TextField
          label="advanced"
          name="contextPromptAdvanced"
          multiline
          className="col-span-2"
          defaultValue={data?.contextPrompt?.advanced}
        />
      </Box>

      <Divider />
      <Typography variant="subtitle2">Other Prompts</Typography>
      <Box className="grid grid-cols-2 gap-4">
        <TextField
          label="Mission Generator"
          name="missionPrompt"
          multiline
          className="col-span-2"
          defaultValue={data?.missionPrompt}
        />
        <TextField
          label="Hint Generator"
          name="hintPrompt"
          multiline
          className="col-span-2"
          defaultValue={data?.hintPrompt}
        />
        <TextField
          label="General Feedback Generator"
          name="generalFeedbackPrompt"
          multiline
          className="col-span-2"
          defaultValue={data?.generalFeedbackPrompt}
        />
        <TextField
          label="General Feedback Translator"
          name="generalFeedbackTranslator"
          multiline
          className="col-span-2"
          defaultValue={TutorResource.getPrompt(
            data,
            'generalFeedbackTranslator'
          )}
        />
        <TextField
          label="Grammar Feedback Generator"
          name="grammarFeedbackPrompt"
          multiline
          className="col-span-2"
          defaultValue={data?.grammarFeedbackPrompt}
        />
        <TextField
          label="Grammar Feedback Translator"
          name="grammarFeedbackTranslator"
          multiline
          className="col-span-2"
          defaultValue={TutorResource.getPrompt(
            data,
            'grammarFeedbackTranslator'
          )}
        />
        <TextField
          label="Summary Generator"
          name="summaryPrompt"
          multiline
          className="col-span-2"
          defaultValue={data?.summaryPrompt}
        />
        <TextField
          label="Summary Translator"
          name="summaryTranslator"
          multiline
          className="col-span-2"
          defaultValue={TutorResource.getPrompt(data, 'summaryTranslator')}
        />
        <TextField
          label="Word Explanation Generator"
          name="wordExplanationEn"
          multiline
          className="col-span-2"
          defaultValue={TutorResource.getPrompt(data, 'wordExplanationEn')}
        />
        <TextField
          label="Word Explanation Generator (JP)"
          name="wordExplanationJp"
          multiline
          className="col-span-2"
          defaultValue={TutorResource.getPrompt(data, 'wordExplanationJp')}
        />
        <TextField
          label="Word Usage Generator"
          name="wordUsageEn"
          multiline
          className="col-span-2"
          defaultValue={TutorResource.getPrompt(data, 'wordUsageEn')}
        />
        <TextField
          label="Word Usage Generator (JP)"
          name="wordUsageJp"
          multiline
          className="col-span-2"
          defaultValue={TutorResource.getPrompt(data, 'wordUsageJp')}
        />
        <TextField
          label="Word Conversation Starter"
          name="wordConversation"
          multiline
          className="col-span-2"
          defaultValue={TutorResource.getPrompt(data, 'wordConversation')}
        />
        <TextField
          label="Word Travel Mode Prompt (beginner)"
          name="travelModeBeginner"
          multiline
          className="col-span-2"
          defaultValue={TutorResource.getPrompt(data, 'travelMode', 'beginner')}
        />
        <TextField
          label="Word Travel Mode Prompt (intermediate)"
          name="travelModeIntermediate"
          multiline
          className="col-span-2"
          defaultValue={TutorResource.getPrompt(
            data,
            'travelMode',
            'intermediate'
          )}
        />
        <TextField
          label="Word Travel Mode Prompt (advanced)"
          name="travelModeAdvanced"
          multiline
          className="col-span-2"
          defaultValue={TutorResource.getPrompt(data, 'travelMode', 'advanced')}
        />

        {/* Design custom input field to better variable types of prompts in different tutors */}
      </Box>

      <Divider />
      <Typography variant="subtitle2">Voice (EN)</Typography>
      <Box className="grid grid-cols-3 gap-4">
        <FormControl fullWidth>
          <InputLabel id="select-voiceJp">Voice Service</InputLabel>
          <Select
            labelId="select-voiceJp"
            label="Voice Service"
            defaultValue={data?.voice?.service || 'google'}
            name="voiceServiceEn"
          >
            <MenuItem value="google">Google</MenuItem>
            <MenuItem value="elevenLabs" disabled>
              ElevenLabs
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Voice Id"
          name="voiceCodeEn"
          defaultValue={data?.voice?.code}
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="start">
          //       <CircularProgress size={24} />
          //     </InputAdornment>
          //   ),
          // }}
        />
        <TextField
          label="Accent"
          name="voiceAccentEn"
          defaultValue={data?.voice.accent}
        />
        <TextField
          type="number"
          label="Beginner"
          defaultValue={data?.voice.speed.beginner || 1}
          name="voiceSpeedBeginnerEn"
          inputProps={{
            step: 0.01,
            min: 0.25,
            max: 4,
          }}
        />
        <TextField
          type="number"
          label="Intermediate"
          defaultValue={data?.voice.speed.intermediate || 1}
          name="voiceSpeedIntermediateEn"
          inputProps={{
            step: 0.01,
            min: 0.25,
            max: 4,
          }}
        />
        <TextField
          type="number"
          label="Advance"
          defaultValue={data?.voice.speed.advanced || 1}
          name="voiceSpeedAdvancedEn"
          inputProps={{
            step: 0.01,
            min: 0.25,
            max: 4,
          }}
        />
      </Box>
      <Divider />
      <Typography variant="subtitle2">Voice (JP)</Typography>
      <Box className="grid grid-cols-3 gap-4">
        <Select
          label="Voice Service"
          defaultValue={data?.voiceJp?.service || 'google'}
          name="voiceServiceJp"
        >
          <MenuItem value="google">Google</MenuItem>
          {/* <MenuItem value="elevenLabs">ElevenLabs</MenuItem> */}
        </Select>
        <TextField
          label="Voice Id"
          name="voiceCodeJp"
          defaultValue={data?.voiceJp.code}
        />
        <TextField
          label="Accent"
          name="voiceAccentJp"
          defaultValue={data?.voiceJp.accent}
        />
        <TextField
          type="number"
          label="Beginner"
          defaultValue={data?.voiceJp.speed.beginner || 1}
          name="voiceSpeedBeginnerJp"
          inputProps={{
            step: 0.01,
            min: 0.25,
            max: 4,
          }}
        />
        <TextField
          type="number"
          label="Intermediate"
          defaultValue={data?.voiceJp.speed.intermediate || 1}
          name="voiceSpeedIntermediateJp"
          inputProps={{
            step: 0.01,
            min: 0.25,
            max: 4,
          }}
        />
        <TextField
          type="number"
          label="Advance"
          defaultValue={data?.voiceJp.speed.advanced || 1}
          name="voiceSpeedAdvancedJp"
          inputProps={{
            step: 0.01,
            min: 0.25,
            max: 4,
          }}
        />
      </Box>
      <Divider />
      <Box className="flex flex-row gap-4">
        <Button
          size="large"
          startIcon={
            busy ? (
              <CircularProgress size={24} />
            ) : (
              <SaveIcon sx={{ height: 24, width: 24 }} />
            )
          }
          type="submit"
          variant="contained"
          disabled={busy}
        >
          Save Tutor
        </Button>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
    </Form>
  );
}
