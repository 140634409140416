import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { FileInput, Form } from '../../design';
import { useRequester } from '../../hooks';
import { CategoryData, LocationData, LocationResource } from '../../resources';
import { useState } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  location: LocationData;
  category?: CategoryData;
};

function makeRequestBody(data: any) {
  return {
    name: data.name,
    nameJp: data.nameJp,
    description: data.description,
    descriptionJp: data.descriptionJp,
    image: data.image,
    items: data.items
      .split(/[\n,]/)
      .filter((item: string) => item.trim().length > 0),
  };
}

export default function CategoryFormModal({
  category,
  location,
  open,
  onClose,
}: Props) {
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState<File>();

  const saveCategory = useRequester<void>({
    onSubmit: (data: any) =>
      category
        ? LocationResource.updateCategory(location.id, category.name, data)
        : LocationResource.addCategory(location.id, data),
    onSuccess: onClose,
    onError: console.log,
  });

  const handleSubmit = async (data: any) => {
    setUploading(true);

    if (image) data.image = await LocationResource.upload(image, 'image');
    saveCategory.submit(makeRequestBody(data));

    setUploading(false);
  };

  const busy = saveCategory.loading || uploading;

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="bg-white absolute top-1/2 left-1/2 w-[800px] transform -translate-x-1/2 -translate-y-1/2 p-8 gap-4 flex flex-col max-h-screen overflow-auto">
        <Box className="flex justify-between">
          <Box className="flex flex-row">
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              {category ? 'Edit Category' : 'Create Category'}
            </Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4"
          disabled={busy}
        >
          <TextField
            required
            name="name"
            label="Name"
            fullWidth
            size="small"
            defaultValue={category?.name}
          />
          <TextField
            name="nameJp"
            label="Name (JP)"
            fullWidth
            size="small"
            defaultValue={category?.nameJp}
          />
          <TextField
            name="description"
            label="Description"
            fullWidth
            multiline
            size="small"
            defaultValue={category?.description}
          />
          <TextField
            name="descriptionJp"
            label="Description (JP)"
            fullWidth
            multiline
            size="small"
            defaultValue={category?.descriptionJp}
          />

          <FileInput
            label="Image"
            name="image"
            accept="image/*"
            onSelect={setImage}
            size="small"
            defaultValue={category?.image}
          />

          <TextField
            name="items"
            label="Items"
            fullWidth
            multiline
            size="small"
            placeholder="Use newline or comma to separate items"
            defaultValue={category?.items.join('\n')}
          />

          <Divider />
          <Box className="flex flex-row gap-4">
            <Button
              startIcon={
                busy ? (
                  <CircularProgress size={20} />
                ) : (
                  <SaveIcon sx={{ height: 20, width: 20 }} />
                )
              }
              type="submit"
              variant="contained"
              disabled={busy}
            >
              Save
            </Button>
            {saveCategory.error && (
              <Alert severity="error">{saveCategory.error}</Alert>
            )}
          </Box>
        </Form>
      </Box>
    </Modal>
  );
}
