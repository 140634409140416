import * as _ from 'lodash';
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Flag as FlagIcon, SaveRounded as SaveIcon } from '@mui/icons-material';
import { FileInput, Form } from '../../design';
import { useForceRerender, useRequester } from '../../hooks';
import { TagResource, LocationData, LocationResource } from '../../resources';

type Props = {
  onSubmit: (data: any) => void;
  saving?: boolean;
  data?: LocationData;
  error?: string;
  label: string;
};

const makeRequestBody = (data: any) => {
  const body = {
    position: {
      longitude: parseFloat(data.longitude),
      latitude: parseFloat(data.latitude),
    },
    tags: data.tags.split(',').filter((tag: string) => tag.trim().length > 0),
    ..._.pick(data, [
      'name',
      'nameJp',
      'description',
      'descriptionJp',
      'flag',
      'image',
    ]),
  };

  return body;
};

export default function LocationForm({
  label,
  onSubmit,
  saving,
  data,
  error,
}: Props) {
  const forceRender = useForceRerender();
  const flagRef = useRef<HTMLInputElement>(null);
  const flag = flagRef.current?.files?.[0];
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState<File>();

  const fetchTags = useRequester<TagResource[]>({
    onSubmit: () => TagResource.fetchMany(),
    onError: console.log,
  });

  useEffect(() => {
    fetchTags.submit();
  }, []);

  const tags = TagResource.list().map((tag) => tag.data);

  const handleSubmit = async (data: LocationData) => {
    setUploading(true);

    const flag = flagRef.current?.files?.[0];
    if (flag) data.flag = await LocationResource.upload(flag, 'image');
    if (image) data.image = await LocationResource.upload(image, 'image');

    const body = makeRequestBody(data);
    onSubmit(body);

    setUploading(false);
  };

  const busy = saving || uploading;

  return (
    <Form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4"
      disabled={busy}
    >
      <Typography variant="h6" sx={{ lineHeight: 1 }}>
        {label}
      </Typography>
      <Divider />
      <Typography variant="subtitle2">Basic Info</Typography>
      <Box className="flex">
        <Box className="h-40 w-40">
          <label className="hover:cursor-pointer">
            {flag ? (
              <>
                <Avatar
                  variant="square"
                  sx={{ height: 100, width: 130 }}
                  src={URL.createObjectURL(flag)}
                />
                <Box textAlign="center" sx={{ width: 130, mt: 1 }}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      flagRef.current!.value = '';
                      forceRender();
                    }}
                  >
                    Clear
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Avatar
                  variant="square"
                  sx={{ height: 100, width: 130 }}
                  src={data?.flag}
                >
                  <FlagIcon />
                </Avatar>
                <Box textAlign="center" sx={{ width: 130, mt: 1 }}>
                  <Button onClick={() => flagRef.current?.click()}>
                    Select Flag
                  </Button>
                </Box>
              </>
            )}

            <input
              ref={flagRef}
              hidden
              type="file"
              accept="image/*"
              onChange={() => setTimeout(forceRender, 100)}
            />
          </label>
        </Box>
        <Box className="flex-grow">
          <Box className="grid grid-cols-3 gap-4">
            <TextField
              label="Name"
              name="name"
              defaultValue={data?.name}
              required
            />
            <TextField
              label="Name (JP)"
              name="nameJp"
              defaultValue={data?.nameJp}
            />
            <FormControl fullWidth disabled={fetchTags.loading}>
              <InputLabel id="select-tags">Tags</InputLabel>
              <Select
                labelId="select-tags"
                multiple
                name="tags"
                defaultValue={data?.tags || []}
                label="Tags"
              >
                {tags.map((tag) => (
                  <MenuItem key={tag.name} value={tag.name}>
                    {tag.label}
                  </MenuItem>
                ))}
                {
                  // Account for the tags that are deleted from the system but
                  // still assigned to the location object
                  data?.tags
                    ?.filter((tag) => !tags.find((t) => t.name === tag))
                    .map((tag) => (
                      <MenuItem key={tag} value={tag}>
                        {tag}
                      </MenuItem>
                    ))
                }
              </Select>
            </FormControl>
          </Box>
          <Box className="grid grid-cols-3 gap-4 mt-4">
            <TextField
              label="Description"
              name="description"
              defaultValue={data?.description}
              multiline
            />
            <TextField
              label="Description (JP)"
              name="descriptionJp"
              defaultValue={data?.descriptionJp}
              multiline
            />
            <FileInput
              label="Image"
              name="image"
              accept="image/*"
              defaultValue={data?.image}
              onSelect={setImage}
            />
          </Box>
        </Box>
      </Box>

      <Divider />

      <Typography variant="subtitle2">Position</Typography>
      <Box className="grid grid-cols-2 gap-4">
        <TextField
          label="Longitude"
          name="longitude"
          defaultValue={data?.position?.longitude}
          required
        />
        <TextField
          label="Latitude"
          name="latitude"
          defaultValue={data?.position?.latitude}
          required
        />
      </Box>

      <Divider />
      <Box className="flex flex-row gap-4">
        <Button
          size="large"
          startIcon={
            busy ? (
              <CircularProgress size={24} />
            ) : (
              <SaveIcon sx={{ height: 24, width: 24 }} />
            )
          }
          type="submit"
          variant="contained"
          disabled={busy}
        >
          Save Location
        </Button>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
    </Form>
  );
}
