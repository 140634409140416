import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useConfirmation, useRequester } from '../../hooks';
import { useEffect } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { Table } from '../../design';
import { MediaData, MediaResource } from '../../resources';

const columns = [
  { id: 'title', label: 'Title', minWidth: 170 },
  { id: 'parser', label: 'Parser' },
  { id: 'url', label: 'API/Feed URL' },
  { id: 'hasApiKey', label: 'API Key' },
];

export default function MediaPage() {
  const navigate = useNavigate();
  const deleteConfirmation = useConfirmation<MediaData>({
    getTitle: (media) => `Delete media '${media.title}?'`,
    getDescription: (media) =>
      `You are about to delete '${media.title}'. It will delete all articles associated with this media. This action cannot be undone.`,
    onConfirm: async (media) => {
      await MediaResource.deleteOne(media.id);
    },
  });

  const mediaApi = useRequester<MediaResource[]>({
    onSubmit: () => MediaResource.fetchMany(),
    onError: console.log,
  });

  useEffect(() => {
    mediaApi.submit();
  }, []);

  const rows = MediaResource.list().map((media) => ({
    id: media.data.id,
    title: media.data.title,
    parser: media.data.parser,
    hasApiKey: media.data.hasApiKey ? '********************' : '',
    url: media.data.url,
  }));

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-4">
          <Typography variant="h5" fontWeight="bold">
            Media
          </Typography>
          {mediaApi.loading && (
            <Box className="flex items-center">
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          onClick={() => navigate('/media/new')}
        >
          New Media
        </Button>
      </Box>
      <Box className="h-4" />

      {mediaApi.error ? (
        <Alert severity="error">{mediaApi.error}</Alert>
      ) : (
        <Table
          columns={columns}
          rows={rows}
          onRowClick={(row) => navigate(`/articles?mediaId=${row.id}`)}
          onEdit={(row) => navigate(`/media/${row.id}/edit`)}
          onDelete={(row) => deleteConfirmation.triggerFor(row as MediaData)}
        />
      )}
    </Box>
  );
}
